import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
  } from '@angular/core';
  import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
  import { Subject, takeUntil } from 'rxjs';
  import { NzMessageService } from 'ng-zorro-antd/message';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { BaseService } from 'src/app/services/base.service';
  import { LIST_IP_ADDRESS, WEBHOOK } from 'src/app/constants/endpoint.const';
  
  @Component({
    selector: 'app-modal-add-webhook',
    templateUrl: './modal-add-webhook.component.html',
    styleUrls: ['./modal-add-webhook.component.scss'],
  })
  export class ModalAddWebhookComponent implements OnInit {
    destroy$ = new Subject();
    formGroupWebhookModal: FormGroup = new FormGroup({
      url: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
      ]),
    });
    isValid: boolean = true;
    isValidPlatform: boolean = true;
    errIP: string = '';
    errPN: string = '';
    @Output() updateWebhook = new EventEmitter();
  
    constructor(
      private activeModal: NgbActiveModal,
      private message: NzMessageService,
      private baseService: BaseService
    ) {}
    ngOnInit(): void {}
  
    close() {
      this.activeModal.close();
    }
  
    setRegexIP(event: any) {
      const regex = /^[a-zA-Z0-9._\-\/]+$/;
      return regex.test(event.key);
    }
  
    setValueRulePlatform() {
      const regex = /^[a-zA-Z0-9:.\\/]+$/;
      const value = this.formGroupWebhookModal.get('url')?.value || '';
      const invalidSequenceRegex = /\.\.+/;
  
      if (value.length === 0) {
        this.isValidPlatform = true;
        this.errPN = '';
      } else if (value.length < 7 && value.length >= 1) {
        this.isValidPlatform = false;
        this.errPN = 'URL must be at least 7 characters long';
      } else if (!regex.test(value)) {
        this.isValidPlatform = false;
        this.errPN = 'Contains not allowed character';
      } else if (invalidSequenceRegex.test(value)) {
        this.isValidPlatform = false;
        this.errPN = 'Format not supported';
      } else {
        if (value.startsWith('https://') || value.startsWith('http://')) {
          this.isValidPlatform = true;
          this.errPN = '';
        } else {
          this.isValidPlatform = false;
          this.errPN =
            'URL must start with https:// or http://';
        }
      }
    }
  
    submit() {
      const params = {
        webhook_url: this.formGroupWebhookModal.get('url')?.value,
      };
      this.baseService
        .putData(WEBHOOK, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.message.create(
              'success',
              'Your webhook url has been successfully saved.'
            );
            this.activeModal.close();
            this.updateWebhook.emit();
          },
          (err) => {
            this.message.create('error', err.error.meta.message);
          }
        );
    }
  }
  