<form [formGroup]="destinationForm">
  <div class="d-flex flex-column p-4">
    <h6 class="text-bold">Destination</h6>
    <hr />
    <div class="mt-3 table-responsive">
      <table class="table">
        <thead class="bg-light">
          <tr>
            <th>Name</th>
            <th>Input</th>
            <th>Expect Result</th>
            <th>cURL</th>
            <th>Result</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let items of uatDestination; let i = index">
            <td class="text-grey col-2">
              <div class="d-flex flex-column">
                {{ items.name || '-' }}
              </div>
            </td>
            <td class="text-grey col-2 fst-italic">
              {{ items.input || '-' }}
            </td>
            <td class="text-grey col-2 fira-code">
              {{ items.expected_result || '-' }}
            </td>
            <td class="col-2">
              <textarea rows="8" nz-input formControlName="curl_destination" placeholder="-"></textarea>
            </td>
            <td class="col-2">
              <textarea rows="8" formControlName="result_destination" nz-input placeholder="-"></textarea>
            </td>
            <td class="col-2">{{ items.notes || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex align-self-end gap-2 py-3">
      <button class="btn btn-outline-secondary" (click)="previus()">Back</button>
      <button [disabled]="destinationForm.invalid" [ngClass]="{
        'btn-primary': destinationForm.valid,
        'btn-secondary': !destinationForm.valid
      }" class="btn text-white" (click)="next()">Next</button>
    </div>
  </div>
</form>
