<div class="d-flex flex-column p-4" [formGroup]="businessForm">
  <h6 class="text-bold">Business Questionnaire</h6>
  <hr />
  <div class="d-flex flex-column gap-3">
    <div class="d-flex gap-2">
      <span>Does your company have a business entity deed?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex gap-2">
      <nz-radio-group formControlName="has_business_entity_deed">
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
    </div>
  </div>

  <!-- Repeat for SPPKP -->
  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span>Does your company have an SPPKP?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex gap-2 col-12 col-lg-4 align-items-center">
      <nz-radio-group
        formControlName="has_taxable_entrepreneur_certificate"
        class="d-flex"
      >
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
      <input
        nz-input
        placeholder="e.g : 873.8238.738278.9723"
        formControlName="taxable_certificate_number"
        type="number"
        (keypress)="preventNonNumeric($event)"
      />
    </div>
  </div>

  <!-- Repeat for NPWP, NIB, etc. -->

  <div class="d-flex flex-column flex-lg-row gap-3 mt-5">
    <div class="d-flex flex-column gap-2 col">
      <div class="d-flex gap-2">
        <span
          >Name of Authorization Holder<span class="text-danger">*</span></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_name"
        placeholder="e.g PT. John Doe"
        type="text"
        (keypress)="setRegexBName($event)"
        (input)="validateInputField($event, 'BName')"
        [nzStatus]="validBName ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errMessBName !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessBName }}</span
      >
      <div class="d-flex gap-2 mt-4">
        <span
          >Email of Authorization Holder<span class="text-danger">*</span></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_email"
        placeholder="e.g example@mail.com"
        type="email"
        (keypress)="setRegex($event)"
        (keydown.enter)="$event.preventDefault()"
        (input)="validateEmail($event)"
        [nzStatus]="validEmail ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errMessEmail !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessEmail }}</span
      >
    </div>

    <div class="d-flex flex-column gap-2 col">
      <div class="d-flex gap-2">
        <span
          >Job Title of Authorization Holder<span class="text-danger"
            >*</span
          ></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_job_title"
        placeholder="e.g Manager"
        type="text"
        (keypress)="setRegexBName($event)"
        (input)="validateInputField($event, 'JName')"
        [nzStatus]="validJName ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errMessJName !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessJName }}</span
      >
      <div class="d-flex gap-2 mt-4">
        <span
          >Address of Authorization Holder<span class="text-danger"
            >*</span
          ></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <nz-textarea-count [nzMaxCharacterCount]="100">
        <textarea
          nz-input
          rows="2"
          formControlName="authorization_holder_address"
          placeholder="e.g Jl.Jend. Soedirman, Jakarta Pusat, 55555"
          (keydown.enter)="$event.preventDefault()"
          (input)="validateAddress($event)"
          [nzStatus]="validAddress ? '' : 'error'"
        ></textarea>
      </nz-textarea-count>
      <span class="text-danger">
        <img
          *ngIf="errMessAddress !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessAddress }}</span
      >
    </div>

    <div class="d-flex flex-column gap-2 col">
      <div class="d-flex gap-2">
        <span
          >Phone Number of Authorization Holder<span class="text-danger"
            >*</span
          ></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_phone"
        placeholder="e.g +62 812 3456 7890"
        type="text"
        (keypress)="preventNonNumeric($event)"
        (input)="validateInput($event)"
        [nzStatus]="validNumber ? '' : 'error'"
      />
      <span class="d-flex align-items-center gap-1 text-danger">
        <img
          *ngIf="errMessNumber !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessNumber }}</span
      >
    </div>
  </div>

  <div class="d-flex align-self-end gap-2 mt-5">
    <button class="btn btn-outline-secondary" (click)="onBack()">Back</button>
    <button
      type="submit"
      class="btn text-white"
      [disabled]="isFormInvalid"
      [ngClass]="{
        'btn-secondary': isFormInvalid,
        'btn-primary': !isFormInvalid
      }"
      (click)="onSubmit()"
    >
      Next
    </button>
  </div>
</div>
