import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TEST_CASE, UAT_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-history-awb',
  standalone: false,
  templateUrl: './uat-history-awb.component.html',
  styleUrl: './uat-history-awb.component.scss',
})
export class UatHistoryAwbComponent {
  tableData: any[] = [];
  destroy$: Subject<void> = new Subject<void>();
  uatCalculate: any[] = [];
  awbForm!: FormGroup;
  current: number = 6;
  @Output() submitUATFinal = new EventEmitter

  constructor(private baseService: BaseService, private fb: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.getUatTestCase();
    this.initForm();
    this.getUatData()
  }

  initForm() {
    this.awbForm = this.fb.group({
      curl20: [null, [Validators.required]],
      curl21: [null, [Validators.required]],
      result20: [null, [Validators.required]],
      result21: [null, [Validators.required]],
    });
  }

  next(): void {
    this.submitUat()
  }
  previus(): void {
    if (this.current > 1) {
      this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current - 1 } });
    }
  }

  getUatTestCase() {
    this.baseService
      .getData(TEST_CASE + '/history_awb')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.tableData = res.data;
        },
        (err) => {}
      );
  }

  getUatData() {
    this.baseService.getData(`${UAT_DATA}/history_awb`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.data.forEach((item: any, index: number) => {
            if (this.awbForm && index < 3) {
              this.awbForm.patchValue({
                [`curl${index + 20}`]: item?.curl,
                [`result${index + 20}`]: item?.result,
              });
            }
            if (this.tableData && this.tableData[index]) {
              this.tableData[index].notes = item?.notes;
            }
          });
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }

  submitUat() {
      const input = this.tableData.map((item, index) => ({
        test_case_id: item.id,
        curl: this.awbForm.get(`curl${index + 20}`)?.value,
        result: this.awbForm.get(`result${index + 20}`)?.value,
      }));

      this.baseService
        .postData(UAT_DATA, { input })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: any) => {
            this.submitUATFinal.emit()
            this.router.navigate([['/document']])
            localStorage.setItem('uat-history-awb', 'complete')
            localStorage.removeItem('mode-uat-input')
          },
          error: (err) => console.error(err.error.data.errors),
        });
    }

}
