import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UserService } from '../../../../../../services/api/user.service';
import { ProfileResponseType } from '../../../../../../types/response/profile-response.type';
import { Subject, takeUntil } from 'rxjs';
import { ResponseCustomType } from '../../../../../../types/response/response.type';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PinModel } from 'src/app/models/pin.model';
import { Router } from '@angular/router';
import { ModalChangeNumberComponent } from './../../components/modal-change-number/modal-change-number.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalDeletePhotoComponent } from './../../components/modal-delete-photo/modal-delete-photo.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../../../../../services/base.service';
import { PROFILE } from 'src/app/constants/endpoint.const';

@Component({
  selector: 'app-profile-general',
  templateUrl: './profile-general.component.html',
  styleUrls: ['./profile-general.component.scss'],
})
export class ProfileGeneralComponent implements OnInit, OnDestroy {
  formGroupProfile: FormGroup = new FormGroup({
    fullname: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(100),
    ]),
  });
  @Input() pin!: PinModel;
  isShow: boolean = false;
  destroy$ = new Subject();
  profile!: ProfileResponseType;
  token!: string;
  @Input() otpParams: any = {
    pin: '',
  };
  otp: string = '000000';
  isErrorMessage: boolean = false;

  settings: any = {
    length: 6,
    numbersOnly: true,
  };

  isDisabled: boolean = true;
  isGeneral: boolean = true;
  isApi: boolean = false;
  isReferrer: boolean = false;
  activedPackage: string = 'starter';
  @ViewChild('fileupload') fileUploadRef!: ElementRef;
  fileUpload: File[] = [];
  uploadedImage: string | null = null;
  errMessFullName: string = '';
  validFullName: boolean = true;
  validAddress: boolean = true;
  errMessAddress: string = '';
  isDisableSave: boolean = true;
  photoProfile: File[] = [];
  isDelete: boolean = false;
  loading: boolean = true;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private userService: UserService,
    private message: NzMessageService,
    private route: Router,
    private BaseService: BaseService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit(): void {
    const queryParams: any = { tab: 'general' };
    this.route.navigate(['profile'], { queryParams });
    this.getProfile();
    setTimeout(()=> {
      this.loading = false
    }, 1000)
  }

  setRegexName(event: any) {
    const regex = /^[a-zA-Z ]+$/g;
    return regex.test(event.key);
  }

  validateFullname() {
    const value = this.formGroupProfile.get('fullname')?.value || '';
    const regex = /^[a-zA-Z ]+$/g;

    if (value === '') {
      this.validFullName = false;
      this.errMessFullName = 'Full Name/Company must be filled';
    } else if (value.length < 3) {
      this.validFullName = false;
      this.errMessFullName =
        'Full Name/Company must be filled at least 3 characters';
    } else if (value.length > 50) {
      this.validFullName = false;
      this.errMessFullName = 'Max 50 characters';
    } else if (!regex.test(value)) {
      this.validFullName = false;
      this.errMessFullName = 'Containt Not Allowed Character';
    } else {
      this.validFullName = true;
      this.errMessFullName = '';
    }

    if (value === this.profile.name) {
      this.isDisableSave = true;
    } else {
      this.isDisableSave = false;
    }
  }

  handleAddress() {
    const regex = /^[a-zA-Z0-9./, ]+$/;
    const value = this.formGroupProfile.get('address')?.value || '';

    if (value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address must be filled';
    } else if (!regex.test(value)) {
      this.validAddress = false;
      this.errMessAddress = 'Containt Not Allowed Character';
    } else if (value.length > 0 && value.length < 6) {
      this.validAddress = false;
      this.errMessAddress = 'Address must be filled at least 6 characters';
    } else {
      this.validAddress = true;
      this.errMessAddress = '';
    }

    if (value === this.profile.address) {
      this.isDisableSave = true;
    } else {
      this.isDisableSave = false;
    }
  }

  changePassword(): void {
    this.route.navigate(['/profile/change-password']);
  }

  handleActivePack(e: number | undefined): string {
    if (e === 1) {
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-starter.svg';
    } else if (e === 2) {
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-pro.svg';
    } else {
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-enterprise.svg';
    }
  }

  moveTab(e: string) {
    const queryParams: any = { tab: e };
    if (e === 'general') {
      this.isGeneral = true;
      this.isApi = false;
      this.isReferrer = false;
    } else if (e === 'api-key') {
      this.isApi = true;
      this.isGeneral = false;
      this.isReferrer = false;
    } else {
      this.isReferrer = true;
      this.isApi = false;
      this.isGeneral = false;
    }
    this.route.navigate(['profile'], { queryParams });
  }

  handleNumber(e: string) {
    const num = e || '';
    if (num.startsWith('0')) return e.slice(1);
    else return e;
  }

  getProfile() {
    this.userService
      .profile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: ResponseCustomType<ProfileResponseType>) => {
        this.profile = res.data;
        this.token = res.data.api_key;
        this.formGroupProfile = new FormGroup({
          fullname: new FormControl(this.profile.name),
          phone_number: new FormControl(this.profile.phone_number),
          address: new FormControl(this.profile.address),
        });
        this.handleActivePack(res.data.rajaongkir_level);
        localStorage.setItem(
          'ro-level',
          JSON.stringify(res.data.rajaongkir_level)
        );
        this.uploadedImage = res.data.photo_profile_url;
      });
  }

  handleSave() {
    const formdata = new FormData();
    formdata.append('name', this.formGroupProfile.get('fullname')?.value);
    formdata.append('address', this.formGroupProfile.get('address')?.value);

    if (this.isDelete === true) {
      formdata.append('delete_picture', 'true');
    } else {
      if (this.photoProfile.length > 1) {
        const lastFile = this.photoProfile[this.photoProfile.length - 1];
        formdata.append('photo_profile', lastFile);
      } else {
        this.photoProfile.forEach((file) => {
          formdata.append('photo_profile', file);
        });
      }
    }

    this.BaseService.putData(PROFILE, formdata)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.getProfile();
          this.isDisableSave = true;
          this.isDelete = false;
          this.message.create('success', 'Success to save update');
        },
        (err) => {
          this.message.create('error', 'Failed to save update');
        }
      );
  }

  handleCancel() {
    this.getProfile();
    this.isDisableSave = true;
  }

  triggerFileInput() {
    this.fileUploadRef.nativeElement.click();
  }
  handleFileUpload(event: any) {
    const files: File[] = event.target.files;

    const allowedFormats = ['png', 'jpg', 'jpeg'];

    for (let file of files) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!allowedFormats.includes(fileExtension as string)) {
        this.message.create(
          'error',
          'File extension must match .png, .jpg, or .jpeg'
        );
        return;
      }
      if (file.size > 1000000) {
        this.message.create('error', 'File size exceeds 1Mb limit');
        return;
      }

      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.uploadedImage = e.target.result;
      };

      reader.readAsDataURL(file);
      this.photoProfile.push(file);
      this.isDisableSave = false;
      this.isDelete = false;
    }
  }

  deleteImage() {
    this.isDelete = true;
    const modal = this.modalService.open(ModalDeletePhotoComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    const modalInstance = modal.componentInstance as ModalDeletePhotoComponent;
    modalInstance.uploadedImage = this.uploadedImage;

    modalInstance.photoDeleted.subscribe(() => {
      this.uploadedImage = null;
      this.photoProfile.slice(1);
      this.isDisableSave = false;
    });
  }

  handleEditNumber() {
    const modal = this.modalService.open(ModalChangeNumberComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    const modalInstance = modal.componentInstance as ModalChangeNumberComponent;
    modalInstance.phoneNumber = this.profile.phone_number;

    modalInstance.changeNumber.subscribe(() => {
      this.getProfile();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
