import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  isGeneral: boolean = true;
  isApi: boolean = false;
  isReferrer: boolean = false;

  constructor(config: NgbModalConfig, private route: Router) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit(): void {
    const queryParams: any = { tab: 'general' };
    this.route.navigate(['profile'], { queryParams });
  }

  moveTab(e: string) {
    const queryParams: any = { tab: e };
    if (e === 'general') {
      this.isGeneral = true;
      this.isApi = false;
      this.isReferrer = false;
    } else if (e === 'api-key') {
      this.isApi = true;
      this.isGeneral = false;
      this.isReferrer = false;
    } else {
      this.isReferrer = true;
      this.isApi = false;
      this.isGeneral = false;
    }
    this.route.navigate(['profile'], { queryParams });
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
