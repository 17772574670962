<div class="card mt-2 h-100 p-3 pb-5 d-flex flex-column gap-3 profiles">
  <div class="title">Profile Settings</div>
  <div class="d-flex mt-2">
    <button
      class="btn d-flex justify-content-center gap-2 align-items-center"
      [ngClass]="isGeneral ? 'active-tab' : 'nonactive-tab'"
      (click)="moveTab('general')"
    >
      <img
        [src]="
          isGeneral
            ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/profile-tick.svg'
            : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/profile-tick.svg'
        "
        alt="general"
      />
      General
    </button>
    <button
      class="btn d-flex justify-content-center gap-2 align-items-center"
      [ngClass]="isApi ? 'active-tab' : 'nonactive-tab'"
      (click)="moveTab('api-key')"
    >
      <img
        [src]="
          isApi
            ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/key.svg'
            : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/key.svg'
        "
        alt="api_key"
      />
      Api Key
    </button>
    <button
      class="btn d-flex justify-content-center gap-2 align-items-center"
      [ngClass]="isReferrer ? 'active-tab' : 'nonactive-tab'"
      (click)="moveTab('referrer')"
    >
      <img
        [src]="
          isReferrer
            ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/book.svg'
            : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/book.svg'
        "
        alt="referrer"
      />
      Referrer
    </button>
  </div>

  <div *ngIf="isGeneral">
    <app-profile-general></app-profile-general>
  </div>
  <div *ngIf="isApi">
    <app-profile-api-key></app-profile-api-key>
  </div>
  <div *ngIf="isReferrer">
    <app-profile-referrer></app-profile-referrer>
  </div>
</div>
