<div class="card mt-2 h-100 p-3 px-4 pb-5 d-flex flex-column gap-3 profiles">
  <div class="title">Change Password</div>
  <div class="mt-3 col-7 col-xl-7 col-sm-12">
    <!-- old pass -->
    <form action="" [formGroup]="formGroupChangePass">
      <div class="form-group mt-3 d-flex align-items-center">
        <label class="col-4" for="oldPassword">Old Password</label>
        <nz-input-group
          nzSize="large"
          [nzSuffix]="suffixTemplateOld"
          [nzStatus]="errMessPassOld === '' ? '' : 'error'"
        >
          <input
            nz-input
            nzSize="large"
            formControlName="oldPassword"
            minlength="8"
            maxlength="32"
            [type]="isShowOldPassword ? 'text' : 'password'"
            placeholder="Enter your old password"
            (keydown.enter)="$event.preventDefault()"
            (input)="oldPassword()"
          />
        </nz-input-group>
        <ng-template #suffixTemplateOld>
          <img
            [src]="isShowOldPassword ? eyeOff : eyeOn"
            style="width: 20px !important; height: 20px"
            (click)="isShowOldPassword = !isShowOldPassword"
          />
        </ng-template>
      </div>
      <div class="d-flex justify-content-between">
        <div class="col-4"></div>
        <span class="col-8 text-danger">
          <img
            *ngIf="errMessPassOld !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessPassOld }}
        </span>
      </div>
      <!-- new pass -->
      <div class="form-group mt-3 d-flex align-items-center">
        <label class="col-4" for="newPassword">New Password</label>
        <nz-input-group
          nzSize="large"
          [nzSuffix]="suffixTemplate"
          [nzStatus]="isValidPass ? '' : 'error'"
        >
          <input
            nz-input
            nzSize="large"
            formControlName="newPassword"
            minlength="6"
            maxlength="32"
            [type]="isShowPassword ? 'text' : 'password'"
            placeholder="Enter your new password"
            (keydown.enter)="$event.preventDefault()"
            (blur)="(visibleValPass === false)"
            (input)="validatePassword()"
            (keyup)="setRegexPassword($event)"
            nz-popover
            [nzPopoverContent]="contentPassValidation"
            nzPopoverPlacement="top"
            [(nzPopoverVisible)]="visibleValPass"
          />
        </nz-input-group>
        <ng-template #contentPassValidation>
          <div *ngFor="let data of validationPassword">
            <div *ngIf="data.text" :key="key" class="d-flex align-items-start">
              <img
                *ngIf="data.status"
                src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-on.svg"
                alt="tick-success"
              />
              <img
                *ngIf="!data.status"
                src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-off.svg"
                alt="tick-success"
              />
              <div class="ms-1" style="font-size: 14px">
                {{ data.text }}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #suffixTemplate>
          <img
            [src]="isShowPassword ? eyeOff : eyeOn"
            style="width: 20px !important; height: 20px"
            (click)="isShowPassword = !isShowPassword"
          />
        </ng-template>
      </div>
      <div class="d-flex justify-content-between">
        <div class="col-4"></div>
        <span class="col-8 text-danger">
          <img
            *ngIf="errMessPass !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessPass }}</span
        >
      </div>
      <!-- confirm -->
      <div class="form-group mt-3 d-flex align-items-center">
        <label class="col-4" for="confirPassword"
          >Confirmation New Password</label
        >
        <nz-input-group
          nzSize="large"
          [nzSuffix]="suffixTemplate2"
          [nzStatus]="errMessPass2 === '' ? '' : 'error'"
        >
          <input
            nz-input
            nzSize="large"
            formControlName="confirmPassword"
            minlength="6"
            maxlength="32"
            [type]="isShowPassword2 ? 'text' : 'password'"
            placeholder="Enter your new password"
            (keydown.enter)="$event.preventDefault()"
            (input)="confirmPassword()"
          />
        </nz-input-group>
        <ng-template #suffixTemplate2>
          <img
            [src]="isShowPassword2 ? eyeOff : eyeOn"
            style="width: 20px !important; height: 20px"
            (click)="isShowPassword2 = !isShowPassword2"
          />
        </ng-template>
      </div>
      <div class="d-flex justify-content-between">
        <div class="col-4"></div>
        <span class="col-8 text-danger">
          <img
            *ngIf="errMessPass2 !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessPass2 }}
        </span>
      </div>
      <div class="d-flex mt-4 justify-content-between">
        <div class="col-4"></div>
        <span class="col-8 d-flex gap-2">
          <button
            class="btn btn-outline-primary"
            style="width: 100px"
            (click)="handleCancel()"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary text-white"
            type="submit"
            [disabled]="
              errMessPass2 !== '' ||
              errMessPass !== '' ||
              errMessPassOld !== '' ||
              formGroupChangePass.invalid
            "
            style="width: 100px"
            (click)="handleSubmit()"
          >
            Save
          </button>
        </span>
      </div>
    </form>
  </div>
</div>
