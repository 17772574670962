import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/services/base.service';
import { PASS_APIKEY } from 'src/app/constants/endpoint.const';

@Component({
  selector: 'app-modal-view-key',
  templateUrl: './modal-view-key.component.html',
  styleUrls: ['./modal-view-key.component.scss'],
})
export class ModalViewKeyComponent implements OnInit {
  destroy$ = new Subject();
  formGroupKey: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  visibleValPass: boolean = false;
  isValidPass: boolean = true;
  isShowPassword: boolean = false;
  errMessPass: string = '';
  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  @Output() showKey = new EventEmitter<void>();

  constructor(
    private message: NzMessageService,
    private activeModal: NgbActiveModal,
    private baseService: BaseService
  ) {}
  ngOnInit(): void {}

  cancel() {
    this.activeModal.close();
  }

  validatePassword() {
    const value = this.formGroupKey.get('password')?.value || '';

    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
      this.isValidPass = false;
    } else if (value.length < 8) {
      this.errMessPass = 'Minimum password is 8 characters';
      this.isValidPass = false;
    } else {
      this.errMessPass = '';
      this.isValidPass = true;
    }
  }

  submit() {
    const params = {
      password: this.formGroupKey.get('password')?.value,
    };
    this.baseService
      .postData(PASS_APIKEY, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.data.is_match === true) {
            this.showKey.emit();
            this.activeModal.close();
          } else {
            this.message.create(
              'error',
              'Incorrect password, please check again!'
            );
            this.errMessPass = 'Incorrect password, please check again!';
            this.isValidPass = false;
          }
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }
}
