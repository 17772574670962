<form [formGroup]="orderForm">
  <div class="d-flex flex-column p-4 gap-5">
    <div class="d-flex flex-column">
      <h6 class="text-bold">Create Order</h6>
      <hr />
      <div class="mt-3 table-responsive">
        <table class="table">
          <thead class="bg-light">
            <tr>
              <th>Name</th>
              <th>Input</th>
              <th>Expected Result</th>
              <th>cURL</th>
              <th>Result</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableCreateOrder">
              <td class="text-grey col-2">{{ row.name || '-' }}</td>
              <td class="text-grey col-2 fst-italic" [innerHTML]="row.input || '-'"></td>
              <td class="text-grey col-2 fira-code" [innerHTML]="row.expected_result || '-'"></td>
              <td class="col-2">
                <textarea [formControlName]="'curl_order' + row.id" nz-input placeholder="-" row="8"></textarea>
              </td>
              <td class="col-2">
                <textarea [formControlName]="'result_order' + row.id" nz-input placeholder="-" row="8"></textarea>
              </td>
              <td class="col-2">{{ row.notes || '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <h6 class="text-bold">Order Detail</h6>
      <hr />
      <div class="mt-3 table-responsive">
        <table class="table">
          <thead class="bg-light">
            <tr>
              <th>Name</th>
              <th>Input</th>
              <th>Expected Result</th>
              <th>cURL</th>
              <th>Result</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableOrderDetail">
              <td class="text-grey col-2">{{ row.name || '-' }}</td>
              <td class="text-grey col-2 fst-italic" [innerHTML]="row.input || '-'"></td>
              <td class="text-grey col-2 fira-code" [innerHTML]="row.expected_result || '-'"></td>
              <td class="col-2">
                <textarea [formControlName]="'curl_order' + row.id" nz-input placeholder="-" row="8"></textarea>
              </td>
              <td class="col-2">
                <textarea [formControlName]="'result_order' + row.id" nz-input placeholder="-" row="8"></textarea>
              </td>
              <td class="col-2">{{ row.notes || '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex flex-column">
      <h6 class="text-bold">Cancel Order</h6>
      <hr />
      <div class="mt-3 table-responsive">
        <table class="table">
          <thead class="bg-light">
            <tr>
              <th>Name</th>
              <th>Input</th>
              <th>Expected Result</th>
              <th>cURL</th>
              <th>Result</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableCancelOrder">
              <td class="text-grey col-2">{{ row.name || '-' }}</td>
              <td class="text-grey col-2 fst-italic" [innerHTML]="row.input || '-'"></td>
              <td class="text-grey col-2 fira-code" [innerHTML]="row.expected_result || '-'"></td>
              <td class="col-2">
                <textarea [formControlName]="'curl_order' + row.id" nz-input placeholder="-" row="8"></textarea>
              </td>
              <td class="col-2">
                <textarea [formControlName]="'result_order' + row.id" nz-input placeholder="-" row="8"></textarea>
              </td>
              <td class="col-2">{{ row.notes || '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex align-self-end gap-2 py-3">
        <button class="btn btn-outline-secondary" (click)="previus()">Back</button>
        <button class="btn text-white" [disabled]="orderForm.invalid" [ngClass]="{
          'btn-primary': orderForm.valid,
          'btn-secondary': !orderForm.valid
        }" (click)="next()">Next</button>
      </div>
    </div>
  </div>
</form>
