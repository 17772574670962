import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { CHANGE_PASSWORD } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  destroy$ = new Subject();

  formGroupChangePass: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
  });

  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  validationPassword = [
    {
      key: 'min8',
      status: false,
      text: 'Minimal 8 karakter',
      error: '8 karakter',
    },
    {
      key: 'minCapital',
      status: false,
      text: 'Minimal 1 huruf besar A-Z',
      error: '1 huruf besar',
    },
    {
      key: 'minText',
      status: false,
      text: 'Minimal 1 huruf kecil a-z',
      error: '1 huruf kecil',
    },
    {
      key: 'minNum',
      status: false,
      text: 'Minimal 1 angka 0-9',
      error: '1 angka',
    },
    {
      key: 'minChar',
      status: false,
      text: "Minimal 1 symbol : ` ~ !  ( ) - + _ = { [ ] } : ; ' . < > /",
      error: '1 symbol',
    },
    { key: 'maxChar', status: false, error: 'Maximal 32 karakter' },
  ];

  errMessPass: string = '';
  visibleValPass: boolean = false;
  isValidPass: boolean = true;
  isShowOldPassword: boolean = false;
  isShowPassword: boolean = false;
  isShowPassword2: boolean = false;
  errMessPass2: string = '';
  isErrPass: boolean = false;
  errMessPassOld: string = '';

  constructor(
    private route: Router,
    private baseService: BaseService,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }

  handleCancel() {
    this.route.navigate(['/profile']);
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  validatePassword() {
    this.visibleValPass = true;
    this.isValidPass = false;
    const min8 = /.{8,}/;
    const minCapital = /(?=.*[A-Z])/;
    const minText = /(?=.*[a-z])/;
    const minNum = /(?=.*[0-9])/;
    const minChar = /[`~!()\-_+={}\[\]:;'.<>\/]/;
    const disallowedChar = /[^a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]/;
    const maxChar = /^.{1,32}$/;

    const value = this.formGroupChangePass.get('newPassword')?.value || '';
    let allRulesValid = true;

    this.validationPassword.forEach((rule) => {
      switch (rule.key) {
        case 'min8':
          rule.status = min8.test(value);
          break;
        case 'minCapital':
          rule.status = minCapital.test(value);
          break;
        case 'minText':
          rule.status = minText.test(value);
          break;
        case 'minNum':
          rule.status = minNum.test(value);
          break;
        case 'minChar':
          rule.status = minChar.test(value) && !disallowedChar.test(value);
          break;
        case 'maxChar':
          rule.status = maxChar.test(value);
          break;
      }

      if (!rule.status) {
        allRulesValid = false;
      }
    });

    this.isValidPass = allRulesValid;

    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
    } else if (!allRulesValid) {
      this.errMessPass = 'Password does not comply with the provisions';
    } else this.errMessPass = '';

    const value2 = this.formGroupChangePass.get('confirmPassword')?.value || '';

    if (value !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation is the same as the new password.';
    } else {
      this.errMessPass2 = '';
    }

    const invalidRules = this.validationPassword.filter((rule) => !rule.status);
    const errors = invalidRules.map((rule) => rule.error);

    this.formGroupChangePass
      .get('newPassword')
      ?.setErrors(
        errors.length > 0 ? { passwordInvalid: errors.join(', ') } : null
      );
  }

  oldPassword() {
    const value = this.formGroupChangePass.get('oldPassword')?.value || '';

    if (value.length === 0) {
      this.errMessPassOld = 'Old Password is must be filled';
    } else if (value.length < 8) {
      this.errMessPassOld = 'Minimum password is 8 characters';
    } else if (value.length > 32) {
      this.errMessPassOld = 'Maximum password is 32 characters';
    } else {
      this.errMessPassOld = '';
    }
  }
  confirmPassword() {
    const value1 = this.formGroupChangePass.get('newPassword')?.value || '';
    const value2 = this.formGroupChangePass.get('confirmPassword')?.value || '';

    if (value1 !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation is the same as the new password.';
    } else if (value2.length === 0) {
      this.errMessPass2 = 'Confirm Password is must be filled';
    } else {
      this.errMessPass2 = '';
    }
  }

  handleSubmit() {
    const params = {
      password: this.formGroupChangePass.get('oldPassword')?.value,
      new_password: this.formGroupChangePass.get('newPassword')?.value,
      confirm_password: this.formGroupChangePass.get('confirmPassword')?.value,
    };
    this.baseService
      .putData(CHANGE_PASSWORD, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create(
            'success',
            'Your password has been successfully changed.'
          );
          this.route.navigate(['/profile']);
        },
        (err) => {
          if (err.error.meta.message === 'old password does not match') {
            this.message.create(
              'error',
              'Your old password is incorrect, please check again.'
            );
            this.errMessPassOld =
              'Your old password is incorrect, please check again.';
          } else {
            this.message.create(
              'error',
              'Failed to save change password, please try again'
            );
          }
        }
      );
  }
}
