import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { DELETE_KEY, DELETE_REF } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-modal-delete-referrer',
  templateUrl: './modal-delete-referrer.component.html',
  styleUrls: ['./modal-delete-referrer.component.scss'],
})
export class ModalDeleteReferrerComponent implements OnInit {
  destroy$ = new Subject();
  @Output() deleteRef = new EventEmitter();
  @Input() idRef: number = 0;
  @Input() typeDevice: string = '';
  title: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private baseService: BaseService,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {
    if (this.typeDevice !== '') {
      this.title = 'Are you sure you want to delete this mobile key?';
    } else {
      this.title = 'Are you sure want to delete this referrer?';
    }
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    if (this.typeDevice === '') {
      this.deleteReferrer();
    } else {
      this.deleteRefMobile();
    }
  }

  deleteReferrer() {
    const params = {
      id: this.idRef,
    };
    this.baseService
      .deleteData(DELETE_REF, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.deleteRef.emit();
          this.activeModal.close();
          this.message.create('success', 'Success delete data referrer');
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }
  deleteRefMobile() {
    const params = {
      id: this.idRef,
    };
    this.baseService
      .deleteData(DELETE_KEY, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.deleteRef.emit();
          this.activeModal.close();
          this.message.create('success', 'Success delete data mobile key');
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }
}
