<form [formGroup]="awbForm">
  <div class="d-flex flex-column p-4">
    <h6 class="text-bold">History AWB</h6>
    <hr />
    <div class="mt-3 table-responsive" style="height: 600px;">
      <table class="table">
        <thead class="bg-light">
          <tr>
            <th>Name</th>
            <th>Input</th>
            <th>Expected Result</th>
            <th>cURL</th>
            <th>Result</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableData">
            <td class="text-grey col-2">{{ row.name || '-' }}</td>
            <td class="text-grey col-2 fst-italic" [innerHTML]="row.input || '-'"></td>
            <td class="text-grey col-2 fira-code" [innerHTML]="row.expected_result || '-'"></td>
            <td class="col-2">
              <textarea [formControlName]="'curl'+row.id" nz-input placeholder="-" nzAutosize></textarea>
            </td>
            <td class="col-2">
              <textarea [formControlName]="'result'+row.id" nz-input placeholder="-" nzAutosize></textarea>
            </td>
            <td class="col-2">{{ row.notes || '-' }}</td>
          </tr>
        </tbody>
      </table>
      <hr>
      <div class="mt-3">
        <span>Validation of Tracking data requires assistance from our team to simulate the delivery, for this can be done by clicking the <a href="https://rajaongkir.com/api" target="_blank">help button</a> which will be directed to our landing page and please contact via <span style="color: #1890ff;">livechat</span> to get access to tracking simulations.</span>
      </div>
    </div>
    <div class="d-flex align-self-end gap-2 py-3">
      <button class="btn btn-outline-secondary" (click)="previus()">Back</button>
      <button class="btn text-white" [disabled]="awbForm.invalid" [ngClass]="{
        'btn-primary': awbForm.valid,
        'btn-secondary': !awbForm.valid
      }" (click)="next()">Submit</button>
    </div>
  </div>
</form>
