<div class="p-4">
    <div class="text-center pt-1">
      <h5><strong>Add Webhook URL</strong></h5>
      <span
        >A webhook is an automatic link between apps, sending data instantly when a specific event occurs. It enables real-time updates without manual checks</span
      >
    </div>
    <form [formGroup]="formGroupWebhookModal">
      <div class="form-group mt-3">
        <label for="url">Webhook URL</label>
        <input
          nz-input
          nzSize="large"
          formControlName="url"
          minlength="7"
          placeholder="https://yourwebhook.url/webhook"
          (keydown.enter)="$event.preventDefault()"
          (input)="setValueRulePlatform()"
          nzSize="large"
          [nzStatus]="isValidPlatform ? '' : 'error'"
        />
        <span class="text-danger">
          <img
            *ngIf="errPN !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errPN }}</span
        >
      </div>
      <div class="mt-4 col-12 d-flex gap-1">
        <button class="col-6 btn btn-outline-primary" (click)="close()">
          Cancel
        </button>
        <button
          class="col-6 btn"
          [ngClass]="
            errIP !== '' || errPN !== '' || formGroupWebhookModal.invalid
              ? 'btn-outline-secondary'
              : 'btn-primary text-white'
          "
          [disabled]="errIP !== '' || errPN !== '' || formGroupWebhookModal.invalid"
          (click)="submit()"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  