import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { DOC_STATE, DOC_UPLOAD } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-ca-submission',
  templateUrl: './ca-submission.component.html',
  styleUrls: ['./ca-submission.component.scss'],
})
export class CaSubmissionComponent {
  loading: boolean = true;
  destroy$ = new Subject();
  caFileForm!: FormGroup;
  fileUploaded: { name: string; path?: string } | null = null;
  isEmpty: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private message: NzMessageService,
    private baseService: BaseService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getExistingFile();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  handleBack(): void {
    this.router.navigate(['/document']);
  }

  initForm(): void {
    this.caFileForm = this.fb.group({
      ca_file: [null, Validators.required],
    });
  }

  triggerFileInput(): void {
    const fileInput: HTMLInputElement = document.getElementById(
      'fileupload_ca_file'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  deleteFile(): void {
    if (this.isEmpty) {
      this.fileUploaded = null;
      this.caFileForm.get('ca_file')?.setValue(null);
    } else {
      this.baseService.deleteData(DOC_UPLOAD + '/collaborator_agreement_file')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.message.success('File deleted successfully.');
          this.fileUploaded = null;
          this.caFileForm.get('ca_file')?.setValue(null);
          this.getExistingFile();
        });
    }
  }
  

  handleFileUpload(event: any): void {
    const file: File = event.target.files[0];
  
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('Only PDF files are allowed.');
        this.deleteFile();
        return;
      }
  
      if (file.size > 500 * 1024) {
        alert('File size must not exceed 500KB.');
        this.deleteFile();
        return;
      }

      this.fileUploaded = {
        name: file.name,
      };
  
      this.caFileForm.get('ca_file')?.setValue(file);
    }
  }
  

  getExistingFile(): void {
    this.baseService.getData(DOC_UPLOAD + '/collaborator_agreement_file')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.data) {
          const { file_name, uploaded_file_path } = res.data;
  
          this.fileUploaded = {
            name: file_name,
            path: uploaded_file_path,
          };
        }
      }, (err) => {
        this.isEmpty = err.error.data === null;
      });
  }
  uploadFile(): void {
    if (this.caFileForm.valid) {
      const formData = new FormData();
      formData.append('document_type', 'collaborator_agreement_file');
      formData.append('notes', '');
      formData.append('file', this.caFileForm.get('ca_file')?.value);

      this.baseService.postData(DOC_UPLOAD, formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.message.success('CA Document uploaded successfully.');
          this.updateState();
        });
    } else {
      this.message.error('Please select a file to upload.');
    }
  }

  updateState(): void {
    const params = {
      document_type: 'CA',
      state: 4,
    };
    this.baseService.putData(DOC_STATE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate(['/document']);
      });
  }
}
