import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { ModalVerifOTPComponent } from '../modal-verif-otp/modal-verif-otp.component';
import { BaseService } from '../../../../../../services/base.service';
import { CHECK_WA, REQUEST_OTP } from 'src/app/constants/endpoint.const';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';

@Component({
  selector: 'app-modal-change-number',
  templateUrl: './modal-change-number.component.html',
  styleUrls: ['./modal-change-number.component.scss'],
})
export class ModalChangeNumberComponent implements OnInit {
  destroy$ = new Subject();
  @Input() phoneNumber: string = '';
  @Input() isConfirm: boolean = false;
  @Output() changeNumber = new EventEmitter();
  isWANumber: boolean = true;
  isWAMess: string = 'Number connected with Whatsapp';
  tokenConvert: string = '';
  title: string = '';
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private message: NzMessageService,
    private BaseService: BaseService,
    private httpClient: HttpClient,
  ) {}
  ngOnInit(): void {
    this.checkWA();
    if (this.isConfirm === true) {
      this.tokenConvert = localStorage.getItem('token-otp-first') || '';
      this.title = 'Choose The OTP Method';
    } else {
      this.tokenConvert = '';
      this.title = 'Edit Phone Number';
    }
  }

  close() {
    this.activeModal.close();
  }

  handleNext(e: string) {
    const params = this.isConfirm
      ? {
          purpose: 'phone',
          type_otp: e,
          phone: this.phoneNumber,
          token: this.tokenConvert,
        }
      : {
          purpose: 'phone',
          type_otp: e,
        };

    this.BaseService.postData(REQUEST_OTP, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.tokenConvert = btoa(res.data.token + '%phone');
          if (this.isConfirm === true) {
            localStorage.setItem('token-otp-sec', this.tokenConvert);
          } else {
            localStorage.setItem('token-otp-first', this.tokenConvert);
          }
          this.activeModal.close();
          const modal = this.modalService.open(ModalVerifOTPComponent, {
            size: 'md',
            backdrop: 'static',
            keyboard: false,
            centered: true,
          });
          modal.componentInstance.phoneNumber = this.phoneNumber;
          modal.componentInstance.tokenVerif = this.tokenConvert;
          modal.componentInstance.isConfirm = this.isConfirm;
          modal.componentInstance.nextRequestAt = res.data.next_request_at;
          modal.componentInstance.typeOTP = e;
          this.changeNumber.emit();
        },
        (err) => {
          if (err.error.meta.message === 'reached limit request otp') {
            this.message.create(
              'error',
              "Well, it looks like you're having trouble verifying your OTP, please contact us via live chat."
            );
          } else {
            this.message.create(
              'error',
              'Failed to request OTP, please try again'
            );
          }
        }
      );
  }

  checkWA() {
    const baseUrl = CHECK_WA;
    const endPoint = `?phone_no=${this.phoneNumber}`;
    const apiUrl = baseUrl + endPoint;
  
    const token = localStorage.getItem('auth-token');
  
    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);

    this.httpClient.post<any>(apiUrl, {}, { headers, context }).subscribe({
      next: (res) => {
        if (res.data === 'valid') {
          this.isWANumber = true;
          this.isWAMess = 'Number connected with Whatsapp';
        } else {
          this.isWANumber = false;
          this.isWAMess = 'Number not connected with Whatsapp';
        }
      },
      error: (error) => {
        if (error.error?.data === 'invalid') {
          this.isWANumber = false;
          this.isWAMess = 'Number not connected with Whatsapp';
        }
        this.message.create('error', 'Failed to check WhatsApp number');
      },
    });
  }
  
}
