<div class="col-12 mt-3 d-flex flex-xl-row flex-column gap-2">
  <div class="col-md-12 col-sm-12 col-xl-4">
    <nz-card class="p-3 rounded" nzHoverable style="height: 420px !important">
      <nz-skeleton [nzLoading]="loading" [nzActive]="true">
      <h6 class="text-bold mb-4">Profile Picture</h6>
      <div
        class="d-flex justify-content-center"
        style="height: 280px"
      >
        <img
          [src]="
            uploadedImage ||
            'https://storage.googleapis.com/komerce/rajaongkir/profile.svg'
          "
          alt="profile"
          class="rounded"
          style="max-width: 280px; max-height: 280px"
        />
      </div>
      <div
        class="d-flex gap-1 mb-2 mt-3 col-12 align-items-end"
      >
        <button
          class="btn btn-primary text-white col-6"
          (click)="triggerFileInput()"
        >
          {{ uploadedImage ? "Edit" : "Upload" }}
        </button>
        <button
          class="btn btn-outline-secondary col-6"
          (click)="deleteImage()"
          [disabled]="!uploadedImage"
        >
          Delete
        </button>
      </div>
      <input
        type="file"
        name="file"
        class="form-control d-none"
        id="attachment"
        (change)="handleFileUpload($event)"
        #fileupload
      />
      </nz-skeleton>
    </nz-card>
  </div>
  <div class="col-md-12 col-sm-12 col-xl-8">
    <nz-card class="p-3 rounded" nzHoverable style="height: 420px !important">
      <nz-skeleton [nzLoading]="loading" [nzActive]="true">
        <div
          class="gap-2 align-items-center mb-4"
          [ngClass]="profile?.is_rajaongkir ? 'd-flex' : 'd-none'"
        >
          <span style="font-size: 16px" class="text-bold">Account Type</span>
          <img
            [src]="handleActivePack(profile?.rajaongkir_level || undefined)"
            alt="package"
          />
          <a
            *ngIf="profile?.rajaongkir_level !== 3"
            href="/billings/upgrade-package"
            style="text-decoration: underline"
            >Upgrade Now</a
          >
        </div>
        <div [ngClass]="profile?.is_rajaongkir ? 'divider' : 'd-none'"></div>
        <form [formGroup]="formGroupProfile">
          <div class="mt-4 d-flex flex-column gap-3">
            <!-- Email -->
            <div class="d-flex align-items-center justify-content-between">
              <span class="col-3">Email</span>
              <input
                type="text"
                class="form-control"
                placeholder="Email"
                [value]="profile?.email"
                disabled
              />
            </div>
  
            <!-- Full Name/Company -->
            <div
              class="form-group d-flex align-items-center justify-content-between"
            >
              <span class="col-3">Full Name/Company</span>
              <div class="d-flex flex-column col-9">
                <input
                  nz-input
                  nzSize="large"
                  type="text"
                  class="form-control"
                  formControlName="fullname"
                  placeholder="Fullname"
                  maxlength="50"
                  minlength="3"
                  (keypress)="setRegexName($event)"
                  (input)="validateFullname()"
                  [ngClass]="{ 'is-invalid': !validFullName }"
                  [value]="profile?.name"
                />
                <span class="text-danger">
                  <img
                    *ngIf="errMessFullName"
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="warning"
                    style="max-width: 10px; max-height: 10px"
                  />
                  {{ errMessFullName }}
                </span>
              </div>
            </div>
  
            <!-- Phone Number -->
            <div class="d-flex align-items-center justify-content-between">
              <span class="col-3">Phone Number</span>
              <div class="input-group">
                <span
                  class="input-group-text"
                  style="background-color: #e8ecee !important"
                  >+62</span
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone Number"
                  [value]="handleNumber(profile?.phone_number || '')"
                  maxlength="12"
                  style="border-radius: 0 8px 8px 0 !important"
                  disabled
                />
              </div>
              <button
                class="btn p-0"
                style="margin-left: 5px"
                (click)="handleEditNumber()"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="edit"
                />
              </button>
            </div>
  
            <!-- Address -->
            <div class="d-flex align-items-center justify-content-between">
              <span class="col-3">Address</span>
              <div class="d-flex flex-column col-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Address"
                  maxlength="50"
                  minlength="10"
                  formControlName="address"
                  [value]="profile?.address"
                  (input)="handleAddress()"
                />
                <div class="d-flex justify-content-between">
                  <span class="text-danger">
                    <img
                      *ngIf="errMessAddress !== ''"
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="warning"
                      style="max-width: 10px; max-height: 10px"
                    />
                    {{ errMessAddress }}</span
                  >
                </div>
              </div>
            </div>
  
            <!-- Password -->
            <div class="d-flex align-items-center">
              <span class="col-3">Password</span>
              <button
                class="btn btn-outline-primary py-0 px-1 text-bold"
                style="font-size: 12px"
                (click)="changePassword()"
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </nz-skeleton>
    </nz-card>
  </div>
</div>
<div
  class="shadow-lg bg-white d-flex align-items-center justify-content-end gap-2 px-4 py-2 mt-5 fixed-bottom"
  style="z-index: 0 !important; height: 60px"
>
  <button
    class="btn btn-outline-primary"
    style="width: 100px"
    (click)="handleCancel()"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary text-white"
    style="width: 100px"
    [disabled]="
      isDisableSave || errMessAddress !== '' || errMessFullName !== ''
    "
    (click)="handleSave()"
  >
    Save
  </button>
</div>
