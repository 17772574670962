import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BUSINESS_TYPE, SLA_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-business-information-input',
  standalone: false,
  templateUrl: './business-information-input.component.html',
  styleUrl: './business-information-input.component.scss',
})
export class BusinessInformationInputComponent {
  destroy$ = new Subject();
  selectedType!: number;
  businessTypeList: any[] = [];
  @Input() slaDataInput: any;
  @Output() businessData = new EventEmitter<any>();
  businessForm!: FormGroup;
  radioValue: number = 0;
  current: number = 1;
  errMessEmail: string = '';
  validEmail: boolean = true;
  errMessBName: string = '';
  validBName: boolean = true;
  validNumber: boolean = true;
  errMessNumber: string = '';
  validAddress: boolean = true;
  errMessAddress: string = '';

  constructor(
    private BaseService: BaseService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getBusinessType();

    if (this.businessForm) {
      this.businessForm.patchValue(this.slaDataInput);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['slaDataInput'] && changes['slaDataInput'].currentValue) {
      const slaData = changes['slaDataInput'].currentValue;

      if (this.businessForm) {
        this.businessForm.patchValue(slaData);
      }
    }
  }

  initForm() {
    this.businessForm = this.fb.group({
      business_entity: [null, Validators.required],
      business_name: ['', Validators.required],
      business_email: ['', [Validators.required, Validators.email]],
      business_type: [null, Validators.required],
      business_address: ['', [Validators.required, Validators.maxLength(100)]],
      business_phone: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
    });
  }

  getBusinessType() {
    this.BaseService.getData(BUSINESS_TYPE)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.businessTypeList = res.data;
        },
        (err) => {}
      );
  }

  onSubmit(): void {
    const mode = localStorage.getItem('mode-sla-input')
    if (this.businessForm.valid) {
      if (this.current < 3) {
        this.current += 1;
        this.businessData.emit(this.businessForm.value);
        if (mode === 'create') {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 2 },
          });
        } else {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 2, mode: mode },
          });
        }
      }
    } else {
      console.log('Form is invalid!');
    }
  }
  previus(): void {
    if (this.current > 0) {
      this.current -= 1;
      this.router.navigate(['/document/sla-data-input'], { queryParams: { step: this.current + 1 } });
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }
  setRegexBName(event: any) {
    const regex = /^[a-zA-Z.-]+$/g;
    return regex.test(event.key);
  }

  validateBusinessName() {
    const value = this.businessForm.get('business_name')?.value || '';

    if (value === '') {
      this.validBName = false
      this.errMessBName = 'Business Name must be filled in';
    } else if (value.length < 3) {
      this.validBName = false;
      this.errMessBName = 'Minimum 3 characters';
    } else if (value.length > 50) {
      this.validBName = false;
      this.errMessBName = 'Maximum 50 characters';
    } else {
      this.validBName = true;
      this.errMessBName = '';
    }
  }

  validateEmail() {
    const value = this.businessForm.get('business_email')?.value || '';
    const regex = /^[a-zA-Z0-9+@._-]+$/g;

    if (value === '') {
      this.validEmail = false;
      this.errMessEmail = 'Email must be filled in';
    } else {
      if (value.length < 10) {
        this.errMessEmail = 'Minimum 10 characters';
        this.validEmail = false;
      } else if (value.length > 50) {
        this.errMessEmail = 'Maximum 50 characters';
        this.validEmail = false;
      } else if (!regex.test(value)) {
        this.validEmail = false;
        this.errMessEmail = 'Email must be a valid email address';
      } else {
        const atIndex = value.indexOf('@');
        const lastDotIndex = value.lastIndexOf('.');
        const dotBeforeAt = value.substring(0, atIndex).includes('.');
        const dotAfterAt = value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === value.length - 1 ||
          value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this.errMessEmail = 'Email must be a valid email address';
          this.validEmail = false;
        } else {
          this.validEmail = true;
          this.errMessEmail = '';
        }
      }
    }
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 0) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must be filled';
    } else if (!/^\d+$/.test(input.value)) {
      this.validNumber = false;
      this.errMessNumber = 'Must be a number';
    } else if (!input.value.startsWith('8')) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must start with number 8';
    } else if (input.value.length < 9) {
      this.validNumber = false;
      this.errMessNumber = 'Minimum 9 digits';
    } else {
      this.validNumber = true;
      this.errMessNumber = '';
    }
  }

  validateAddress() {
    const value = this.businessForm.get('business_address')?.value || '';
    const regex = /^[a-zA-Z0-9.\-():=/, ]+$/;

    if (value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address cannot be empty';
    } else if (!regex.test(value)) {
      this.validAddress = false;
      this.errMessAddress = 'There are characters that are not allowed';
    } else if (value.length > 0 && value.length < 6) {
      this.validAddress = false;
      this.errMessAddress = 'Minimum 6 characters';
    } else {
      this.validAddress = true;
      this.errMessAddress = '';
    }
  }

  get isFormInvalid(): boolean {
    return (
      this.businessForm.invalid ||
      this.errMessEmail !== '' ||
      this.errMessBName !== '' ||
      this.errMessAddress !== '' ||
      this.errMessNumber !== ''
    );
  }
}
