import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TEST_CASE, UAT_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-pickup',
  standalone: false,
  templateUrl: './uat-pickup.component.html',
  styleUrl: './uat-pickup.component.scss',
})
export class UatPickupComponent {
  tableData: any[] = [];
  destroy$: Subject<void> = new Subject<void>();
  pickupForm!: FormGroup;
  current: number = 4;

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.getTestCase();
    this.initForm();
    this.getUatData()
  }

  initForm() {
    const formControls: { [key: string]: any[] } = {};
    for (let i = 17; i <= 18; i++) {
      formControls[`curl_pickup${i}`] = [null, [Validators.required]];
      formControls[`result_pickup${i}`] = [null, [Validators.required]];
    }
    this.pickupForm = this.fb.group(formControls);
  }

  next(): void {
    this.submitUat()
    this.router.navigate(['/document/uat-data-input'], {
      queryParams: { step: this.current + 1 },
    });
  }
  previus(): void {
    if (this.current > 1) {
      this.router.navigate(['/document/uat-data-input'], {
        queryParams: { step: this.current - 1 },
      });
    }
  }

  getTestCase() {
    this.baseService
      .getData(TEST_CASE + '/pickup')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.tableData = res.data;
        },
        (err) => {}
      );
  }

  getUatData() {
    this.baseService.getData(`${UAT_DATA}/pickup`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.data.forEach((item: any, index: number) => {
            if (this.pickupForm && index < 18) {
              this.pickupForm.patchValue({
                [`curl_pickup${index + 17}`]: item?.curl,
                [`result_pickup${index + 17}`]: item?.result,
              });
            }
            if (this.tableData && this.tableData[index]) {
              this.tableData[index].notes = item?.notes;
            }
          });
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }

  submitUat() {
    const input = this.tableData.map((item, index) => ({
      test_case_id: item.id,
      curl: this.pickupForm.get(`curl_pickup${index + 17}`)?.value,
      result: this.pickupForm.get(`result_pickup${index + 17}`)?.value,
    }));

    this.baseService
      .postData(UAT_DATA, { input })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          localStorage.setItem('uat-pickup', 'complete')
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }
}
