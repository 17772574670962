import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TEST_CASE, UAT_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-print-label',
  standalone: false,
  templateUrl: './uat-print-label.component.html',
  styleUrl: './uat-print-label.component.scss',
})
export class UatPrintLabelComponent {
  tableData: any[] = [];
  destroy$: Subject<void> = new Subject<void>();
  printForm!: FormGroup;
  current: number = 5;

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.getUatTestCase();
    this.initForm();
    this.getUatData()
  }

  initForm() {
    this.printForm = this.fb.group({
      curl: [null, [Validators.required]],
      result: [null, [Validators.required]],
    });
  }

  next(): void {
    this.submitUat()
    this.router.navigate(['/document/uat-data-input'], {
      queryParams: { step: this.current + 1 },
    });
  }
  previus(): void {
    if (this.current > 1) {
      this.router.navigate(['/document/uat-data-input'], {
        queryParams: { step: this.current - 1 },
      });
    }
  }

  getUatTestCase() {
    this.baseService
      .getData(TEST_CASE + '/print_label')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.tableData = res.data;
        },
        (err) => {}
      );
  }

  getUatData() {
      this.baseService.getData(UAT_DATA + '/print_label')
      .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          this.printForm.patchValue({
            curl: res.data[0].curl,
            result: res.data[0].result
          })
          this.tableData[0].notes = res.data[0].notes
        }, (err) => {
          console.error(err.error.data.errors)
        });
  }

  submitUat() {
    const input = [
      {
        test_case_id: this.tableData[0].id,
        curl: this.printForm.get('curl')?.value,
        result: this.printForm.get('result')?.value,
      }
    ]
    this.baseService.postData(UAT_DATA, { input })
    .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        localStorage.setItem('uat-print-label', 'complete')
      }, (err) => {
        console.error(err.error.data.errors)
      });
  }
}
