<div class="d-flex justify-content-end px-4 mt-4">
  <button class="btn p-0" (click)="close()">
    <img
      src="https://storage.googleapis.com/komerce/assets/LP-Komcard/close-circle.svg"
      alt="close"
      width="20px"
    />
  </button>
</div>
<div
  class="d-flex text-center align-items-center justify-content-center flex-column text-center px-5 pt-1 pb-5"
>
  <img
    src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png"
    alt="alert"
    width="150px"
    class="mb-3"
  />
  <h5><strong>Are you sure want to delete your profile picture ?</strong></h5>
  <div class="col-12 mt-4 d-flex gap-2">
    <button class="btn btn-primary text-white col-6" (click)="close()">
      No
    </button>
    <button class="btn btn-outline-primary col-6" (click)="deletePhoto()">
      Yes
    </button>
  </div>
</div>
