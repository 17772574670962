<form [formGroup]="caFileForm">
  <div class="card p-3 d-flex flex-column gap-3 mt-2">
    <nz-skeleton [nzLoading]="loading" [nzActive]="true" class="d-flex flex-column">
      <div class="d-flex align-items-center gap-3">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
          alt="back"
          (click)="handleBack()"
        />
        <div class="title">Submission CA Document</div>
      </div>
      <h6 class="text-bold mt-5">Upload Your CA Document</h6>
      <div class="table-responsive" style="height: 600px;">
        <table class="table mt-4">
          <thead class="bg-light">
            <tr>
              <th></th>
              <th>
                <div class="d-flex flex-column">
                  Document
                  <small class="text-light fst-italic" style="font-weight: 400;">
                    (Upload CA legal documents)
                  </small>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="col-5">
                Collaboration Agreement signed by the client
                <span class="text-danger">*</span>
              </td>
              <td class="col">
                <div class="d-flex gap-2 align-items-center">
                  <input
                    type="file"
                    formControlName="ca_file"
                    (change)="handleFileUpload($event)"
                    #fileupload
                    id="fileupload_ca_file"
                    class="form-control d-none"
                    accept=".pdf"
                  />
                  <button nz-button class="rounded d-flex align-items-center" (click)="triggerFileInput()">
                    <span nz-icon nzType="upload"></span>
                    <span>Upload</span>
                  </button>
                  <div class="d-flex align-items-center" style="margin-bottom: 5px;" *ngIf="fileUploaded">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/document-link.svg"
                      alt=""
                      width="16px"
                    />
                    <a
                      class="mx-2"
                      style="color: #08a0f7; font-size: 12px; text-decoration: underline; cursor: pointer;"
                      *ngIf="fileUploaded.path"
                      [href]="fileUploaded.path"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ fileUploaded.name }}
                    </a>
                    <span class="mx-2" style="color: #08a0f7; font-size: 12px;" *ngIf="!fileUploaded.path">
                      {{ fileUploaded.name }}
                    </span>
                    <button
                      class="btn d-flex justify-content-center align-items-center"
                      style="background-color: rgb(242, 242, 242); height: 18px; width: 14px;"
                      (click)="deleteFile()"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                        alt=""
                        width="12px"
                      />
                    </button>
                  </div>
                </div>
                
                <small class="text-light fst-italic">PDF - Max 500KB</small>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <div class="d-flex gap-2 align-self-end">
        <button class="btn btn-outline-secondary" (click)="handleBack()">Back</button>
        <button class="btn text-white" [disabled]="!fileUploaded" [ngClass]="{'btn-primary' : fileUploaded, 'btn-secondary': !fileUploaded}" (click)="uploadFile()">Send Request</button>
      </div>
    </nz-skeleton>
  </div>
</form>
