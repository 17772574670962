<div class="p-4">
  <div class="text-center pt-1">
    <h5><strong>Add Mobile Key</strong></h5>
    <span
      >Secure your API Key for Android and iOS apps by verifying requests
      through matching certificates (Android) or package names (iOS), ensuring
      only authorized applications can access the API.</span
    >
  </div>
  <div class="form-group d-flex flex-column mt-3 gap-2">
    <label for="mobileDevice">Mobile Key for:</label>
    <nz-radio-group
      [(ngModel)]="deviceValue"
      (ngModelChange)="handleDevice()"
      nzSize="large"
    >
      <label nz-radio nzValue="android">Android</label>
      <label nz-radio nzValue="ios">iOS</label>
    </nz-radio-group>
  </div>
  <div *ngIf="isDisabledInput" class="form-group mt-3 d-flex flex-column gap-2">
    <label for="keyCertificate">Key Certificate</label>
    <input nz-input nzSize="large" placeholder="-" disabled />
  </div>
  <form [formGroup]="formGroupMobKey">
    <div
      *ngIf="!isDisabledInput"
      class="form-group mt-3 d-flex flex-column gap-2"
    >
      <label for="keyCertificate">Key Certificate</label>
      <input
        nz-input
        nzSize="large"
        formControlName="keyCertificate"
        minlength="40"
        maxlength="70"
        placeholder="Input application key"
        (keydown.enter)="$event.preventDefault()"
        (input)="setValueRule()"
        nzSize="large"
        [nzStatus]="isValid ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errKey !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errKey }}</span
      >
    </div>
    <div class="form-group mt-3 d-flex flex-column gap-2">
      <label for="package">Package Name</label>
      <input
        nz-input
        nzSize="large"
        formControlName="package"
        minlength="2"
        maxlength="50"
        placeholder="com.app.yourapp"
        (keydown.enter)="$event.preventDefault()"
        (input)="setValueRulePlatform()"
        nzSize="large"
        [nzStatus]="isValidPackage ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errPackage !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errPackage }}</span
      >
    </div>
    <div class="mt-4 col-12 d-flex gap-1">
      <button class="col-6 btn btn-outline-primary" (click)="close()">
        Cancel
      </button>
      <button
        class="col-6 btn"
        [ngClass]="
          errKey !== '' || errPackage !== '' || formGroupMobKey.invalid
            ? 'btn-outline-secondary'
            : 'btn-primary text-white'
        "
        [disabled]="
          errKey !== '' || errPackage !== '' || formGroupMobKey.invalid
        "
        (click)="submit()"
      >
        Submit
      </button>
    </div>
  </form>
</div>
