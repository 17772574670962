import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-delete-photo',
  templateUrl: './modal-delete-photo.component.html',
  styleUrls: ['./modal-delete-photo.component.scss'],
})
export class ModalDeletePhotoComponent implements OnInit {
  @Input() uploadedImage: string | null = null;
  @Output() photoDeleted = new EventEmitter<void>();
  constructor(
    private activeModal: NgbActiveModal,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }

  deletePhoto() {
    this.message.create('success', 'Successfully delete profile photo');
    this.activeModal.close();
    this.photoDeleted.emit();
  }
}
