import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-questionaire-input',
  standalone: false,
  templateUrl: './business-questionaire-input.component.html',
  styleUrl: './business-questionaire-input.component.scss'
})
export class BusinessQuestionaireInputComponent {
  [key: string]: any;
  businessForm!: FormGroup;
  current: number = 2;
  radioValue: string = '1';
  @Input() slaDataInput: any;
  @Output() businessData = new EventEmitter<any>();
  errMessEmail: string = '';
  validEmail: boolean = true;
  errMessBName: string = '';
  validBName: boolean = true;
  errMessJName: string = '';
  validJName: boolean = true;
  validNumber: boolean = true;
  errMessNumber: string = '';
  validAddress: boolean = true;
  errMessAddress: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initForm();

    if (this.businessForm) {
      this.businessForm.patchValue(this.slaDataInput);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['slaDataInput'] && changes['slaDataInput'].currentValue) {
        const slaData = changes['slaDataInput'].currentValue;

        if (this.businessForm) {
          this.businessForm.patchValue(slaData);
        }
      }
    }

  initForm() {
    this.businessForm = this.fb.group({
      has_business_entity_deed: [null],
      has_taxable_entrepreneur_certificate: [null],
      taxable_certificate_number: [{ value: '', disabled: true }],
      authorization_holder_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      authorization_holder_email: ['', [Validators.required, Validators.email]],
      authorization_holder_job_title: ['', Validators.required],
      authorization_holder_address: ['', [Validators.required, Validators.maxLength(100)]],
      authorization_holder_phone: ['', [Validators.required, Validators.pattern('^[0-9]*\\.?[0-9]*$')]],
    });

    this.businessForm.get('has_taxable_entrepreneur_certificate')?.valueChanges.subscribe(value => {
      const certificateControl = this.businessForm.get('taxable_certificate_number');
      value ? certificateControl?.enable() : certificateControl?.disable();
    });
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 0) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must be filled';
    } else if (!/^\d+$/.test(input.value)) {
      this.validNumber = false;
      this.errMessNumber = 'Must be a number';
    } else if (!input.value.startsWith('8')) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must start with number 8';
    } else if (input.value.length < 9) {
      this.validNumber = false;
      this.errMessNumber = 'Minimum 9 digits';
    } else {
      this.validNumber = true;
      this.errMessNumber = '';
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }

  validateEmail(event: any) {
    const input = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9+@._-]+$/g;

    if (input.value === '') {
      this.validEmail = false;
      this.errMessEmail = 'Email must be filled in';
    } else {
      if (input.value.length < 10) {
        this.errMessEmail = 'Minimum 10 characters';
        this.validEmail = false;
      } else if (input.value.length > 50) {
        this.errMessEmail = 'Maximum 50 characters';
        this.validEmail = false;
      } else if (!regex.test(input.value)) {
        this.validEmail = false;
        this.errMessEmail = 'Email must be a valid email address';
      } else {
        const atIndex = input.value.indexOf('@');
        const lastDotIndex = input.value.lastIndexOf('.');
        const dotBeforeAt = input.value.substring(0, atIndex).includes('.');
        const dotAfterAt = input.value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === input.value.length - 1 ||
          input.value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this.errMessEmail = 'Email must be a valid email address';
          this.validEmail = false;
        } else {
          this.validEmail = true;
          this.errMessEmail = '';
        }
      }
    }
  }

  setRegexBName(event: any) {
    const regex = /^[a-zA-Z.-]+$/g;
    return regex.test(event.key);
  }

  validateInputField(event: any, fieldType: 'BName' | 'JName') {
    const input = event.target as HTMLInputElement;
    const minLength = 3;
    const maxLength = 50;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;

    if (input.value === '') {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `${fieldType === 'BName' ? 'Business' : 'Job'} Name must be filled in`;
    } else if (input.value.length < minLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Minimum ${minLength} characters`;
    } else if (input.value.length > maxLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Maximum ${maxLength} characters`;
    } else {
      this[fieldValidKey] = true;
      this[fieldErrorKey] = '';
    }
  }


  validateAddress(event: any) {
    const input = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9.\-():=/, ]+$/;

    if (input.value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address cannot be empty';
    } else if (!regex.test(input.value)) {
      this.validAddress = false;
      this.errMessAddress = 'There are characters that are not allowed';
    } else if (input.value.length > 0 && input.value.length < 6) {
      this.validAddress = false;
      this.errMessAddress = 'Minimum 6 characters';
    } else {
      this.validAddress = true;
      this.errMessAddress = '';
    }
  }

  onBack(): void {
    if (this.current > 1) {
      this.current -= 1;
      this.router.navigate(['/document/sla-data-input'], { queryParams: { step: 1 } });
    }
  }
  onSubmit(): void {
    const mode = localStorage.getItem('mode-sla-input')
    if (this.businessForm.valid) {
        this.current = 2;
        this.businessData.emit(this.businessForm.value);
        if (mode === 'create') {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 3 },
          });
        } else {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 3, mode: mode },
          });
        }
    } else {
      console.log('Form is invalid!');
    }
  }

  get isFormInvalid(): boolean {
    return (
      this.businessForm.invalid ||
      this.errMessEmail !== '' ||
      this.errMessBName !== '' ||
      this.errMessAddress !== '' ||
      this.errMessNumber !== ''
    );
  }
}
