<form [formGroup]="caInputForm">
  <div class="card p-3 d-flex flex-column gap-3 mt-2">
    <nz-skeleton
      [nzLoading]="loading"
      [nzActive]="true"
      class="d-flex flex-column"
    >
      <div class="d-flex align-items-center gap-3">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
          alt="back"
          (click)="handleBack()"
        />
        <div class="title">Collaboration Agreement Data</div>
      </div>
      <h6 class="text-bold mt-5">
        Submission Data for Collaboration Agreement Documents (PKS)
      </h6>
      <hr />
      <div class="mt-3 d-flex gap-2 mb-3">
        <div class="d-flex flex-column gap-2 col">
          <div class="d-flex gap-2">
            <span>Business Name<span class="text-danger">*</span></span>
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="business_name"
            nz-input
            placeholder="e.g PT. John Doe"
            type="text"
            (keypress)="setRegexBName($event)"
            (input)="validateBusinessName()"
            [nzStatus]="validBName ? '' : 'error'"
          />
          <span class="text-danger">
            <img
              *ngIf="errMessBName !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessBName }}</span
          >
          <div class="d-flex gap-2 mt-4">
            <span>Business Email<span class="text-danger">*</span></span>
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="business_email"
            nz-input
            placeholder="e.g johndoe@example.com"
            type="email"
            (keypress)="setRegex($event)"
            (input)="validateEmail($event, 'BEmail')"
            [nzStatus]="validBEmail ? '' : 'error'"
          />
          <span class="text-danger">
            <img
              *ngIf="errMessBEmail !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessBEmail }}</span
          >
        </div>
        <div class="d-flex flex-column gap-2 col">
          <div class="d-flex gap-2">
            <span
              >Business Person in Charge<span class="text-danger">*</span></span
            >
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="business_pic"
            nz-input
            placeholder="e.g John Doe"
            type="text"
            (input)="validateInput($event, 'PicName')"
            [nzStatus]="validPicName ? '' : 'error'"
          />
          <span class="text-danger">
            <img
              *ngIf="errMessPicName !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessPicName }}</span
          >
        </div>
        <div class="d-flex flex-column gap-2 col">
          <div class="d-flex gap-2">
            <span
              >Job Tittle of Person in Charge<span class="text-danger"
                >*</span
              ></span
            >
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="job_title_pic"
            nz-input
            placeholder="e.g Manager"
            type="text"
            (input)="validateInput($event, 'PicJob')"
            [nzStatus]="validPicJob ? '' : 'error'"
          />
          <span class="text-danger">
            <img
              *ngIf="errMessPicJob !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessPicJob }}</span
          >
        </div>
      </div>
      <h6 class="text-bold mt-5">Correspondence Data</h6>
      <hr />
      <div class="mt-3 d-flex gap-2">
        <div class="d-flex flex-column gap-2 col">
          <div class="d-flex gap-2">
            <span>Name<span class="text-danger">*</span></span>
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="correspondence_name"
            nz-input
            placeholder="e.g PT. John Doe"
            type="text"
            (input)="validateInput($event, 'CName')"
            [nzStatus]="validCName ? '' : 'error'"
          />
          <span class="text-danger">
            <img
              *ngIf="errMessCName !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessCName }}</span
          >
          <div class="d-flex gap-2 mt-4">
            <span>Address<span class="text-danger">*</span></span>
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <nz-textarea-count [nzMaxCharacterCount]="100">
            <textarea
              nz-input
              formControlName="correspondence_address"
              rows="2"
              maxlength="100"
              placeholder="e.g Jl.Jend. Soedirman, Jakarta Pusat, 55555"
              (keydown.enter)="$event.preventDefault()"
              (input)="validateAddress($event)"
              [nzStatus]="validAddress ? '' : 'error'"
            ></textarea>
          </nz-textarea-count>
          <span class="text-danger">
            <img
              *ngIf="errMessAddress !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessAddress }}</span
          >
        </div>
        <div class="d-flex flex-column gap-2 col">
          <div class="d-flex gap-2">
            <span>Phone Number<span class="text-danger">*</span></span>
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="correspondence_phone_number"
            nz-input
            placeholder="e.g 8123xxxxxx"
            type="number"
            (keypress)="preventNonNumeric($event)"
            (input)="validateInputNumber($event)"
            [nzStatus]="validBNumber ? '' : 'error'"
          />
          <span class="d-flex align-items-center gap-1 text-danger">
            <img
              *ngIf="errMessBNumber !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessBNumber }}</span
          >
        </div>
        <div class="d-flex flex-column gap-2 col">
          <div class="d-flex gap-2">
            <span>Email<span class="text-danger">*</span></span>
            <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
          </div>
          <input
            formControlName="correspondence_email"
            nz-input
            placeholder="e.g johndoe@example.com"
            type="email"
            (keypress)="setRegex($event)"
            (input)="validateEmail($event, 'CEmail')"
            [nzStatus]="validCEmail ? '' : 'error'"
          />
          <span class="text-danger">
            <img
              *ngIf="errMessCEmail !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessCEmail }}</span
          >
        </div>
      </div>
      <hr />
      <div class="d-flex gap-2 align-self-end">
        <button class="btn btn-outline-secondary" (click)="handleBack()">
          Back
        </button>
        <button class="btn text-white" [disabled]="isFormInvalid"
        [ngClass]="{
          'btn-secondary': isFormInvalid,
          'btn-primary': !isFormInvalid
        }" (click)="onSubmit()">
          Send Request
        </button>
      </div>
    </nz-skeleton>
  </div>
</form>
