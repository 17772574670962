<div class="p-4">
  <div class="text-center px-5 pt-1">
    <h5><strong>Add Referrer</strong></h5>
    <span
      >Add the web IP address you want to whitelist, so that your integration
      access is not restricted.</span
    >
  </div>
  <form [formGroup]="formGroupReferrer">
    <div class="form-group mt-3">
      <label for="ipAddress">IP Address</label>
      <input
        nz-input
        nzSize="large"
        formControlName="ipAddress"
        minlength="5"
        maxlength="32"
        placeholder="xxx.xxx.xxx.xxx"
        (keydown.enter)="$event.preventDefault()"
        (input)="setValueRule()"
        (keyup)="setRegexIP($event)"
        nzSize="large"
        [nzStatus]="isValid ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errIP !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errIP }}</span
      >
    </div>
    <div class="form-group mt-3">
      <label for="platform">Platform Name</label>
      <input
        nz-input
        nzSize="large"
        formControlName="platform"
        minlength="5"
        maxlength="32"
        placeholder="https://domain.com"
        (keydown.enter)="$event.preventDefault()"
        (input)="setValueRulePlatform()"
        nzSize="large"
        [nzStatus]="isValidPlatform ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errPN !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errPN }}</span
      >
    </div>
    <div class="mt-4 col-12 d-flex gap-1">
      <button class="col-6 btn btn-outline-primary" (click)="close()">
        Cancel
      </button>
      <button
        class="col-6 btn"
        [ngClass]="
          errIP !== '' || errPN !== '' || formGroupReferrer.invalid
            ? 'btn-outline-secondary'
            : 'btn-primary text-white'
        "
        [disabled]="errIP !== '' || errPN !== '' || formGroupReferrer.invalid"
        (click)="submit()"
      >
        Submit
      </button>
    </div>
  </form>
</div>
