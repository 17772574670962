<div class="login-container">
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div
        class="d-flex flex-column gap-3 col-lg-6 col-xl-5 col-12 col-md-6 justify-content-center text-center"
      >
        <div class="d-flex justify-content-center">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div>
          <div
            class="px-md-5 px-3 py-5 m-3 card shadow d-flex flex-column gap-3 justify-content-center text-center align-items-center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-on.svg"
              alt="check"
              class="mt-3"
              style="max-width: 50px; max-height: 50px"
            />
            <h5><strong>Verification Successful</strong></h5>
            <span style="color: #828282"
              > Congratulations<span class="text-yellow">{{ email }}</span>, the RajaOngkir service is now active</span
            >
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-ro-primary text-white mt-3"
                (click)="goToLogin()"
              >
                Login Now
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
