import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/services/base.service';
import { LIST_IP_ADDRESS } from 'src/app/constants/endpoint.const';

@Component({
  selector: 'app-modal-add-referrer',
  templateUrl: './modal-add-referrer.component.html',
  styleUrls: ['./modal-add-referrer.component.scss'],
})
export class ModalAddReferrerComponent implements OnInit {
  destroy$ = new Subject();
  formGroupReferrer: FormGroup = new FormGroup({
    ipAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    platform: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
  });
  isValid: boolean = true;
  isValidPlatform: boolean = true;
  errIP: string = '';
  errPN: string = '';
  @Output() addRef = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    private message: NzMessageService,
    private baseService: BaseService
  ) {}
  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }

  setRegexIP(event: any) {
    const regex = /^[a-zA-Z0-9._\-\/]+$/;
    return regex.test(event.key);
  }

  setValueRule() {
    const regex = /^[a-zA-Z0-9._\-\/]+$/;
    const alphanumericRegex = /[a-zA-Z0-9]/g;
    const invalidSequenceRegex = /\.\.+/;

    const value = this.formGroupReferrer.get('ipAddress')?.value || '';
    const alphanumericLength = (value.match(alphanumericRegex) || []).length;

    if (value.length === 0) {
      this.isValid = false;
      this.errIP = 'IP Address is required';
    } else if (alphanumericLength < 5) {
      this.isValid = false;
      this.errIP = 'IP Address must be at least 5 characters long';
    } else if (!regex.test(value)) {
      this.isValid = false;
      this.errIP = 'Contains not allowed character';
    } else if (invalidSequenceRegex.test(value)) {
      this.isValid = false;
      this.errIP = 'Format not supported';
    } else {
      this.isValid = true;
      this.errIP = '';
    }
  }

  setValueRulePlatform() {
    const regex = /^[a-zA-Z0-9:._\-\/]+$/;
    const value = this.formGroupReferrer.get('platform')?.value || '';
    const invalidSequenceRegex = /\.\.+/;

    if (value.length === 0) {
      this.isValidPlatform = false;
      this.errPN = 'Platform Name is required';
    } else if (value.length < 5) {
      this.isValidPlatform = false;
      this.errPN = 'Platform Name must be at least 5 characters long';
    } else if (!regex.test(value)) {
      this.isValidPlatform = false;
      this.errPN = 'Contains not allowed character';
    } else if (invalidSequenceRegex.test(value)) {
      this.isValidPlatform = false;
      this.errPN = 'Format not supported';
    } else {
      if (value.startsWith('https://') || value.startsWith('http://')) {
        this.isValidPlatform = true;
        this.errPN = '';
      } else {
        this.isValidPlatform = false;
        this.errPN =
          'Please enter a valid URL starting with http:// or https://';
      }
    }
  }

  submit() {
    const params = {
      ip_address: this.formGroupReferrer.get('ipAddress')?.value,
      platform_name: this.formGroupReferrer.get('platform')?.value,
    };
    this.baseService
      .postData(LIST_IP_ADDRESS, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create(
            'success',
            'Your referrer has been successfully added.'
          );
          this.activeModal.close();
          this.addRef.emit();
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }
}
