<div class="d-flex px-4 mt-4"></div>
<div class="text-center px-5 pt-1 pb-5">
  <h5><strong>Enter Your Password</strong></h5>
  <span>To view the API Key, please enter your password</span>
  <form [formGroup]="formGroupKey">
    <div class="form-group mt-3">
      <nz-input-group
        nzSize="large"
        [nzPrefix]="prefixTemplatePass"
        [nzSuffix]="suffixTemplate"
        [nzStatus]="isValidPass ? '' : 'error'"
      >
        <input
          nz-input
          nzSize="large"
          formControlName="password"
          minlength="8"
          maxlength="32"
          [type]="isShowPassword ? 'text' : 'password'"
          placeholder="Input your account password"
          (keydown.enter)="$event.preventDefault()"
          (input)="validatePassword()"
        />
      </nz-input-group>
      <span class="text-danger">
        <img
          *ngIf="errMessPass !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessPass }}</span
      >
      <ng-template #suffixTemplate>
        <img
          [src]="isShowPassword ? eyeOff : eyeOn"
          style="width: 20px !important; height: 20px"
          (click)="isShowPassword = !isShowPassword"
        />
      </ng-template>
      <ng-template #prefixTemplatePass>
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/key.svg"
          alt="key"
          width="20px"
        />
      </ng-template>
    </div>
    <div class="d-flex col-12 gap-1 mt-4">
      <button class="col-6 btn btn-outline-primary" (click)="cancel()">
        Cancel
      </button>
      <button
        class="col-6 btn"
        [ngClass]="
          errMessPass !== '' || formGroupKey.invalid
            ? 'btn-outline-secondary'
            : 'btn-primary text-white'
        "
        [disabled]="errMessPass !== '' || formGroupKey.invalid"
        (click)="submit()"
      >
        Show Key
      </button>
    </div>
  </form>
</div>
