import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/services/base.service';
import { LIST_KEY } from 'src/app/constants/endpoint.const';

@Component({
  selector: 'app-modal-add-mob-key',
  templateUrl: './modal-add-mob-key.component.html',
  styleUrls: ['./modal-add-mob-key.component.scss'],
})
export class ModalAddMobKeyComponent implements OnInit {
  destroy$ = new Subject();
  formGroupMobKey: FormGroup = new FormGroup({
    keyCertificate: new FormControl('', [
      Validators.minLength(40),
      Validators.maxLength(70),
    ]),
    package: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(50),
    ]),
  });
  deviceValue: string = 'android';
  isValid: boolean = true;
  isValidPackage: boolean = true;
  isDisabledInput: boolean = false;
  @Output() addMobKey = new EventEmitter();
  errKey: string = '';
  errPackage: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private message: NzMessageService,
    private baseService: BaseService
  ) {}
  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }

  handleDevice() {
    if (this.deviceValue === 'android') this.isDisabledInput = false;
    else this.isDisabledInput = true;
  }

  submit() {
    const params =
      this.deviceValue === 'android'
        ? {
            type: this.deviceValue,
            certificate: this.formGroupMobKey.get('keyCertificate')?.value,
            package_name: this.formGroupMobKey.get('package')?.value,
          }
        : {
            type: this.deviceValue,
            package_name: this.formGroupMobKey.get('package')?.value,
          };
    this.baseService
      .postData(LIST_KEY, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create(
            'success',
            'Your mobile key has been successfully added.'
          );
          this.activeModal.close();
          this.addMobKey.emit();
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }

  setValueRule() {
    const regex = /^[a-zA-Z0-9]+$/;

    const value = this.formGroupMobKey.get('keyCertificate')?.value || '';

    if (value.length === 0) {
      this.isValid = false;
      this.errKey = 'Key Certificate is required';
    } else if (value.length < 40) {
      this.isValid = false;
      this.errKey = 'Key must contain at least 40 characters';
    } else if (!regex.test(value)) {
      this.isValid = false;
      this.errKey = 'Containt not allowed character';
    } else {
      this.isValid = true;
      this.errKey = '';
    }
  }
  setValueRulePlatform() {
    const regex = /^[a-zA-Z0-9.]+$/;
    const value = this.formGroupMobKey.get('package')?.value || '';
    const alphanumericRegex = /[a-zA-Z0-9]/g;

    const alphanumericLength = (value.match(alphanumericRegex) || []).length;

    if (value.length === 0) {
      this.isValidPackage = false;
      this.errPackage = 'Package Name is required';
    } else if (alphanumericLength < 2) {
      this.isValidPackage = false;
      this.errPackage = 'Package Name must contain at least 2 characters';
    } else if (!regex.test(value)) {
      this.isValidPackage = false;
      this.errPackage = 'Containt not allowed character';
    } else {
      this.isValidPackage = true;
      this.errPackage = '';
    }
  }
}
