import { Component, Input, OnInit, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Subject, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalViewKeyComponent } from '../modal-view-key/modal-view-key.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/services/base.service';
import {
  LIST_APIKEY,
  LIST_APIKEY_COST,
  LIST_APIKEY_DELIVERY,
  SHOW_APICOST,
  SHOW_APIDELIVERY,
} from 'src/app/constants/endpoint.const';
import { Clipboard } from '@angular/cdk/clipboard';
import { DecimalPipe } from '@angular/common';
import { ModeSwitchService } from 'src/app/services/mode-switch.service';
import { environment as prodEnv } from 'src/environments/environment.prod';
import { environment as devEnv } from 'src/environments/environment';
import { environment as sandEnv } from 'src/environments/environment.sandbox';

@Component({
  selector: 'app-profile-api-key',
  templateUrl: './profile-api-key.component.html',
  styleUrls: ['./profile-api-key.component.scss'],
  animations: [
    trigger('collapseExpand', [
      state(
        'collapsed',
        style({
          height: '0px',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('collapsed => expanded', [animate('300ms ease-out')]),
      transition('expanded => collapsed', [animate('300ms ease-in')]),
    ]),
  ],
  providers: [DecimalPipe],
})
export class ProfileApiKeyComponent implements OnInit {
  destroy$ = new Subject();
  listOfData: any[] = [];
  listOfCost: any[] = [];
  listOfDelivery: any[] = [];
  expandSC: boolean = false;
  expandSD: boolean = false;
  @Input() appName: string = '';
  apiKeyCost: string = '';
  apiKeyDelivery: string = '';
  roLevel: string = '';
  loading: boolean = true;
  currentMode = localStorage.getItem('mode');
  isShowLimit: boolean = true;

  constructor(
    private message: NzMessageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private baseService: BaseService,
    private clipboard: Clipboard,
    private modeSwitchService: ModeSwitchService,
  ) {}
  ngOnInit(): void {
    this.getListData();
    this.roLevel = localStorage.getItem('ro-level') || '';
    setTimeout(()=>{
      this.loading = false
    }, 1000)

    if (this.currentMode === 'development' && this.modeSwitchService.getCurrentMode() === devEnv.BASE_URL) {
      this.isShowLimit = false
    } else if (this.currentMode === 'sandbox' && this.modeSwitchService.getCurrentMode() === sandEnv.BASE_URL) {
      this.isShowLimit = false
    } else {
      this.isShowLimit = true
    }
  }

  showKey(e: string) {
    const modal = this.modalService.open(ModalViewKeyComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    const modalInstance = modal.componentInstance as ModalViewKeyComponent;

    modalInstance.showKey.subscribe(() => {
      if (e === 'cost') {
        this.getKeyCost();
      } else {
        this.getKeyDelivery();
      }
    });
  }

  copyValue(e: string) {
    this.clipboard.copy(e);
    this.message.create('success', 'Successfully copy API Key');
  }

  hideKey(e: string) {
    if (e === 'cost') {
      this.apiKeyCost = '';
    } else {
      this.apiKeyDelivery = '';
    }
  }
  getListData() {
    this.baseService
      .getData(LIST_APIKEY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listOfData = res.data;
          this.getListCost();
          this.getListDelivery();
        },
        (err) => {
          this.message.create('error', 'Failed to get list Api Key');
        }
      );
  }
  getListCost() {
    this.baseService
      .getData(LIST_APIKEY_COST)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listOfCost = res.data;
        },
        (err) => {
          this.message.create('error', 'Failed to get list Api Key');
        }
      );
  }

  getListDelivery() {
    this.baseService
      .getData(LIST_APIKEY_DELIVERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listOfDelivery = res.data;
        },
        (err) => {
          this.message.create('error', 'Failed to get list Api Key');
        }
      );
  }

  getKeyCost() {
    this.baseService
      .getData(SHOW_APICOST)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create('success', 'Successfully open API Key');
          this.apiKeyCost = res.data.api_key;
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }
  getKeyDelivery() {
    this.baseService
      .getData(SHOW_APIDELIVERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create('success', 'Successfully open API Key');
          this.apiKeyDelivery = res.data.api_key;
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }

  classMethod(e: string) {
    if (e === 'GET') {
      return 'get';
    } else if (e === 'POST') {
      return 'post';
    } else {
      return 'put';
    }
  }

  apiName(e: string) {
    if (e === 'internationalDestination') {
      return 'International Destination';
    } else if (e === 'internationalOrigin') {
      return 'International Origin';
    } else if (e === 'internationalCost') {
      return 'International Cost';
    } else if (e === 'request') {
      return 'Pickup';
    } else {
      return e;
    }
  }
}
