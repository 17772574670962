import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KomerceDateRangePickerComponent } from './components/komerce-date-range-picker/komerce-date-range-picker.component';
import { KomerceInputComponent } from './components/komerce-input/komerce-input.component';
import { KomerceSearchbarComponent } from './components/komerce-searchbar/komerce-searchbar.component';
import { KomerceShimmerComponent } from './components/komerce-shimmer/komerce-shimmer.component';
import {IdrCurrencyPipe} from "./pipe/idr-currency.pipe";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalAlertSwitchComponent } from './components/modal-alert-switch/modal-alert-switch.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AntDModule } from '../modules/shared/antd.module';


@NgModule({
  declarations: [
    KomerceDateRangePickerComponent,
    KomerceInputComponent,
    KomerceSearchbarComponent,
    KomerceShimmerComponent,
    ModalAlertSwitchComponent,
    IdrCurrencyPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    AntDModule,
    ReactiveFormsModule,
  ],
  exports: [
    KomerceDateRangePickerComponent,
    KomerceInputComponent,
    KomerceSearchbarComponent,
    ModalAlertSwitchComponent,
    IdrCurrencyPipe
  ]
})
export class LibsModule { }
