import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TEST_CASE, UAT_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-order',
  standalone: false,
  templateUrl: './uat-order.component.html',
  styleUrl: './uat-order.component.scss',
})
export class UatOrderComponent {
  tableCreateOrder: any[] = [];
  tableOrderDetail: any[] = [];
  tableCancelOrder: any[] = [];
  destroy$: Subject<void> = new Subject<void>();
  orderForm!: FormGroup;
  current: number = 3;

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

    ngOnInit() {
      this.getUatTestCase();
      this.initForm();
      this.getUatData()
    }

  initForm() {
    const formControls: { [key: string]: any[] } = {};
    for (let i = 5; i <= 16; i++) {
      formControls[`curl_order${i}`] = [null, [Validators.required]];
      formControls[`result_order${i}`] = [null, [Validators.required]];
    }
    this.orderForm = this.fb.group(formControls);
  }

  next(): void {
    this.submitUat()
    this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current + 1 } });
  }
  previus(): void {
    if (this.current > 1) {
      this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current - 1 } });
    }
  }

    getUatTestCase() {
      this.baseService
        .getData(TEST_CASE + '/order')
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: any) => {
            this.tableCreateOrder = res.data.filter((item: any) => item.id <= 13);
            this.tableOrderDetail = res.data.filter((item: any) => item.id === 14);
            this.tableCancelOrder = res.data.filter((item: any) => item.id > 14);
          },
          (err) => {}
        );
  }

  getUatData() {
    this.baseService.getData(`${UAT_DATA}/order`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.data.forEach((item: any, index: number) => {
            if (this.orderForm && index < 17) {
              this.orderForm.patchValue({
                [`curl_order${index + 5}`]: item?.curl,
                [`result_order${index + 5}`]: item?.result,
              });
            }
            if (this.tableCreateOrder && this.tableCreateOrder[index]) {
              this.tableCreateOrder[index].notes = item?.notes;
            }
            if (this.tableOrderDetail && this.tableOrderDetail[index]) {
              this.tableOrderDetail[index].notes = item?.notes;
            }
            if (this.tableCancelOrder && this.tableCancelOrder[index]) {
              this.tableCancelOrder[index].notes = item?.notes;
            }
          });
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }

  submitUat() {
    const generateInput = (table: any[], offset: number) =>
      table.map((item, index) => ({
        test_case_id: item.id,
        curl: this.orderForm.get(`curl_order${index + offset}`)?.value,
        result: this.orderForm.get(`result_order${index + offset}`)?.value,
      }));

    const input = [
      ...generateInput(this.tableCreateOrder, 5),
      ...generateInput(this.tableOrderDetail, 14),
      ...generateInput(this.tableCancelOrder, 15),
    ];

    this.baseService.postData(UAT_DATA, { input })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          localStorage.setItem('uat-order', 'complete')
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }

}
