import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import { ModalBuatPinComponent } from '../../components/modal-buat-pin/modal-buat-pin.component';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/api/user.service';
import { Subject, takeUntil } from 'rxjs';
import { PinModel } from 'src/app/models/pin.model';
import { ProfileResponseType } from 'src/app/types/response/profile-response.type';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { MaintenanceComponent } from 'src/app/modules/shared/components/maintenance/maintenance.component';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit{
  @Input() pin!: PinModel
  @ViewChild('changePIN', { static: false }) changePINModal: any;

  settings: any = {
    length: 6,
    numbersOnly: true,
  };
  isDisabled: boolean = true;
  isErrorMessage: boolean = false;
  @Input() otpParams: any = {
    pin: '',
  };
  otp: string = '000000';
  destroy$ = new Subject()
  profile!: ProfileResponseType;
  loading: boolean = true;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private userService: UserService,
    private toastService: ToastService,
  ) {
    config.backdrop = 'static';
		config.keyboard = false;
  }
  ngOnInit(): void {
    this.getProfile()
    setTimeout(()=>{
      this.loading = false
    }, 1000)
  }

  getProfile(){
    this.userService
    .profile()
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: ResponseCustomType<ProfileResponseType>) => {
      this.profile = res.data
    })
  }

  open(m: any) {
		this.modalService.open(m, { centered: true });
	}
  close(value: any) {
		this.modalService.dismissAll(value);
    this.isErrorMessage = false;
	}
  changePIN(changePIN: any) {
		this.modalService.open(changePIN, { centered: true });
	}

  lupaPinClik(e: Event): void {
    const modal = this.modalService.open(MaintenanceComponent, {
      centered: true,
      backdrop: "static"
    })
  }
  buatPinClik(e: Event): void {
    const modal = this.modalService.open(ModalBuatPinComponent, {
      centered: true,
      backdrop: "static"
    })
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.isErrorMessage = false;
      this.isDisabled = true;
    }
  }

  onChange(e: string) {
    this.otp = e;
    if (e.length === 6) {
      this.isDisabled = false;
    }
  }

  handleConfirm(changePIN:any) {
    this.otpParams.pin = this.otp
    this.userService
      .checkPin(this.otpParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.data.is_match === true) {
        this.pin = res.data
        this.openModal(this.changePINModal)
      } else {
        this.isErrorMessage = true
      }
      })
  }

  handleUpdate(e:any) {
    this.otpParams.pin = this.otp
    this.userService
      .updatePin(this.otpParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.meta.code === 200) {
        this.pin = res.data
        this.toastService.show(null, 'Successfully Changed PIN', 'success', {
          classname: 'bg-success',
          delay: 5000,
        });
        this.modalService.dismissAll(e);
      } else {
      }
      })
  }
  handleCreate(e:any) {
    this.otpParams.pin = this.otp
    this.userService
      .updatePin(this.otpParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.meta.code === 200) {
        this.pin = res.data
        this.toastService.show(null, 'Successfully Created PIN', 'success', {
          classname: 'bg-success',
          delay: 5000,
        });
        this.modalService.dismissAll(e);
        window.location.reload()
      } else {
      }
      })
  }
  openModal(changePIN: any) {
    this.modalService.open(changePIN, { centered: true });
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
