<div class="d-flex justify-content-end px-4 mt-4">
  <button class="btn p-0" (click)="close()">
    <img
      src="https://storage.googleapis.com/komerce/assets/LP-Komcard/close-circle.svg"
      alt="close"
      width="20px"
    />
  </button>
</div>
<div
  class="d-flex text-center align-items-center justify-content-center flex-column text-center px-5 pt-1 pb-5"
>
  <h5>
    <strong>{{ title }}</strong>
  </h5>
  <span>Choose one of the methods below to get the verification code</span>
  <div class="col-12 mt-4 d-flex flex-column justify-content-between gap-1">
    <button
      class="btn"
      (click)="handleNext('whatsapp')"
      [disabled]="!isWANumber"
    >
      <nz-card
        class="p-3 rounded border d-flex align-items-center"
        nzHoverable
        style="height: 100px !important"
      >
        <div class="d-flex gap-3">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
            alt="whatsapp"
            width="40px"
          />
          <div
            class="d-flex flex-column text-left justify-content-start align-items-start"
          >
            <h6>Whatsapp OTP</h6>
            <span>+62{{ phoneNumber }}</span>
            <span [ngClass]="isWANumber ? 'text-green' : 'text-danger'">{{
              isWAMess
            }}</span>
          </div>
        </div>
      </nz-card>
    </button>
    <button class="btn" (click)="handleNext('sms')">
      <nz-card
        class="p-3 rounded border d-flex align-items-center"
        nzHoverable
        style="height: 100px !important"
      >
        <div class="d-flex gap-3">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/sms.svg"
            alt="sms"
            width="40px"
          />
          <div
            class="d-flex flex-column text-left justify-content-start align-items-start"
          >
            <h6>SMS OTP</h6>
            <span>+62{{ phoneNumber }}</span>
          </div>
        </div>
      </nz-card>
    </button>
  </div>
</div>
