import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Input,
  Output,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { BalanceService } from '../../services/api/balance.service';
import { BaseService } from 'src/app/services/base.service';
import { Subject, takeUntil } from 'rxjs';
import { NgbModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ResponseCustomType } from '../../types/response/response.type';
import { BalanceResponseType } from '../../types/response/balance-response.type';
import { PossibleIncomeResponseType } from '../../types/response/possible-income-response.type';
import { OrderService } from '../../services/api/order.service';
import { AccumulationDataOrderResponseType } from '../../types/response/accumulation-data-order-response.type';
import { ModalTarikSaldoComponent } from './components/modal-tarik-saldo/modal-tarik-saldo.component';
import { CANCEL_INVOICE, CHECK_ACTIVE } from 'src/app/constants/endpoint.const';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import localeId from '@angular/common/locales/id';
import id from '@angular/common/locales/id';
import { registerLocaleData } from '@angular/common';
import { MaintenanceComponent } from 'src/app/modules/shared/components/maintenance/maintenance.component';
import { ProfileResponseType } from 'src/app/types/response/profile-response.type';
import { UserService } from 'src/app/services/api/user.service';
import { BuatRekeningComponent } from './components/modal-buat-rekening/buat-rek.component';
import { BankService } from 'src/app/services/api/bank.service';
import { BankModel } from 'src/app/models/bank.model';
import { BuatPinComponent } from './components/modal-buat-pin/buat-pin.component';
import { ModalTarikKomisiComponent } from './components/modal-tarik-komisi/modal-tarik-kom.component';
import { wdListModel } from 'src/app/models/withdrawl-list.model';
import { BlokReqWDComponent } from 'src/app/modules/shared/components/modal-blocked-request-wd/blocked-req-wd.component';
import { ModalTopUpSaldoComponent } from 'src/app/modules/shared/components/modal-top-up-balance/modal-top-up-balance.component';
import { ModalCheckPaymentComponent } from 'src/app/modules/shared/components/modal-check-payment/modal-check-payment.component';
import { NzMessageService } from 'ng-zorro-antd/message';
registerLocaleData(localeId);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(DaterangepickerDirective, { static: false })
  datePicker!: DaterangepickerDirective;
  destroy$ = new Subject();
  profile!: ProfileResponseType;
  bankList: BankModel[] = [];
  @Input() wdList: wdListModel[] = [];
  balance: BalanceResponseType = {
    balance: 0,
    commission: 0,
  };

  possibleIncome: PossibleIncomeResponseType = {
    possible_income: 0,
  };

  accumulationOrder: any;

  startDate = '';
  endDate = '';

  selected: any = {
    startDate: moment().startOf('month').locale('id'),
    endDate: moment().endOf('month').locale('id'),
  };

  ranges: any = {
    Today: [moment(), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 1 Month': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Input() filter: any = {
    start_date: moment().startOf('month').locale('id').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').locale('id').format('YYYY-MM-DD'),
  };

  @Input() filterList: any = {
    start_date: '2019-01-01',
    end_date: moment().format('YYYY-MM-DD'),
    type: 'balance',
  };

  @Output() filterResult = new EventEmitter();
  setlocale: LocaleConfig = {
    applyLabel: 'Save',
    cancelLabel: 'Back',
    separator: ' - ',
    clearLabel: 'Cancel',
    format: 'DD MMM YYYY',
    displayFormat: 'DD MMM YYYY',
    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  };
  keepCalendarOpeningWithRange: boolean;
  pinStatus!: boolean;
  modalBlock!: boolean;
  loading = true;
  isSandBoxMode = false;

  constructor(
    private balanceService: BalanceService,
    private orderService: OrderService,
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    public baseService: BaseService,
    private bankService: BankService,
    private message: NzMessageService,
    private BaseService: BaseService,
  ) {
    this.keepCalendarOpeningWithRange = true;
  }
  ngOnInit(): void {
    moment.updateLocale('id', id);
    this.getBalance();
    this.getPossibleIncome();
    this.getAccumulationOrder();
    this.getBankList();
    this.getListWD('balance');
    if (localStorage.getItem('mode') === 'sandbox') {
      this.isSandBoxMode = true
    } else {
      this.isSandBoxMode = false
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  openDatepicker() {
    this.datePicker.open();
  }

  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filter.start_date = startDate.format('YYYY-MM-DD');

    this.filter.end_date = endDate.format('YYYY-MM-DD');

    this.filterResult.emit(this.filter);
    this.getAccumulationOrder();
  }
  getPossibleIncome(): void {
    this.balanceService
      .possibleIncome()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<PossibleIncomeResponseType>) => {
          this.possibleIncome = res.data;
        },
        (error) => {}
      );
  }
  getBalance(): void {
    this.balanceService
      .balance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<BalanceResponseType>) => {
          this.balance = res.data;
        },
        (error) => {}
      );
  }

  handleTarikSaldo(e: any, type: string): void {
    const filterList: any = {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      type: 'balance',
    };

    this.balanceService
      .withdrawlList(filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const dataList = res.data;

          let modalBlock = false;

          if (dataList !== null) {
            for (let i = 0; i < dataList.length; i++) {
              if (
                (dataList[i].type === 'balance' &&
                  dataList[i].status === 'request') ||
                dataList[i].status === 'review'
              ) {
                modalBlock = true;
                break;
              }
            }

            if (modalBlock === true) {
              this.modalService.open(BlokReqWDComponent, {
                backdrop: 'static',
                centered: true,
              });
            } else {
              this.modalService.open(ModalTarikSaldoComponent, {
                backdrop: 'static',
                centered: true,
              });
            }
          } else {
            this.modalService.open(ModalTarikSaldoComponent, {
              backdrop: 'static',
              centered: true,
            });
          }
        },
        (error) => {}
      );
  }
  handleTarikKomisi(e: any, type: string): void {
    const filterList: any = {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      type: 'commission',
    };

    this.balanceService
      .withdrawlList(filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const dataList = res.data;

          let modalBlock = false;

          if (dataList !== null) {
            for (let i = 0; i < dataList.length; i++) {
              if (
                (dataList[i].type === 'commission' &&
                  dataList[i].status === 'request') ||
                dataList[i].status === 'review'
              ) {
                modalBlock = true;
                break;
              }
            }

            if (modalBlock === true) {
              this.modalService.open(BlokReqWDComponent, {
                backdrop: 'static',
                centered: true,
              });
            } else {
              this.modalService.open(ModalTarikKomisiComponent, {
                backdrop: 'static',
                centered: true,
              });
            }
          } else {
            this.modalService.open(ModalTarikKomisiComponent, {
              backdrop: 'static',
              centered: true,
            });
          }
        },
        (error) => {}
      );
  }
  handleNoRek(e: any): void {
    this.modalService.open(BuatRekeningComponent, {
      backdrop: 'static',
      centered: true,
    });
  }
  handleNoPIN(e: any): void {
    this.modalService.open(BuatPinComponent, {
      backdrop: 'static',
      centered: true,
    });
  }

  maintenance(e: any): void {
    this.modalService.open(MaintenanceComponent, {
      backdrop: 'static',
      centered: true,
    });
  }
  getAccumulationOrder(): void {
    this.orderService
      .accumulationDataOrder(this.filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<AccumulationDataOrderResponseType>) => {
          this.accumulationOrder = res.data;
        },
        (error) => {}
      );
  }

  getBankList(): void {
    this.bankService
      .bankList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.bankList = res.data;
        },
        (error) => {}
      );
  }

  getListWD(type: string) {
    this.balanceService
      .withdrawlList(this.filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.wdList = res.data;
        },
        (error) => {}
      );
  }

  handleTopUp() {
    this.baseService
      .getData(CHECK_ACTIVE + '?type=balance')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const currentTime = new Date();
          const expireDate = new Date(res.data.qr_expire_date);

          if (expireDate < currentTime && res.data.have_active_bill === true) {
            if (res.data.qr_xendit_id !== '') {
              this.handleCancel(res.data.qr_xendit_id);
            } else {
              const modal = this.modalService.open(ModalCheckPaymentComponent, {
                backdrop: 'static',
                centered: true,
                size: 'md',
                keyboard: false,
              });
              modal.componentInstance.invoiceID =
                res.data.invoice_xendit_id === ''
                  ? res.data.qr_xendit_id
                  : res.data.invoice_xendit_id;
              modal.componentInstance.allowQris = res.data.allow_qr;
              modal.componentInstance.expiredAt = res.data.qr_expire_date;
              modal.componentInstance.urlPayment = res.data.invoice_xendit_url;
              modal.componentInstance.qrCode = res.data.qr_xendit_qrstring;

              modal.componentInstance.isQris = res.data.invoice_xendit_id === '';
            }
          } else if (res.data.have_active_bill === false) {
            const modal = this.modalService.open(ModalTopUpSaldoComponent, {
              backdrop: 'static',
              centered: true,
              size: 'md',
              keyboard: false,
            });
          } else {
            const modal = this.modalService.open(ModalCheckPaymentComponent, {
              backdrop: 'static',
              centered: true,
              size: 'md',
              keyboard: false,
            });
            modal.componentInstance.invoiceID =
              res.data.invoice_xendit_id === ''
                ? res.data.qr_xendit_id
                : res.data.invoice_xendit_id;
            modal.componentInstance.allowQris = res.data.allow_qr;
            modal.componentInstance.expiredAt = res.data.qr_expire_date;
            modal.componentInstance.urlPayment = res.data.invoice_xendit_url;
            modal.componentInstance.qrCode = res.data.qr_xendit_qrstring;
            modal.componentInstance.totalPrice = res.data.qr_amount;

            modal.componentInstance.isQris = res.data.invoice_xendit_id === '';
          }
        },
        (err) => {
          this.message.create('error', err.error.data.errors);
        }
      );
  }
  handleCancel(e: string) {
    const params = {
      invoice_id: e,
    };
    this.BaseService.putData(CANCEL_INVOICE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const modal = this.modalService.open(ModalTopUpSaldoComponent, {
            backdrop: 'static',
            centered: true,
            size: 'md',
            keyboard: false,
          });
        },
        (err) => {
        }
      );
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
