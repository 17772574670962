<div class="px-4 py-5">
  <div class="text-center px-5 pt-1">
    <h5><strong>Enter New Number</strong></h5>
    <span>Please enter your new number below</span>
  </div>
  <form [formGroup]="formGroupInputNumber">
    <div class="form-group mt-3">
      <nz-input-group nzAddOnBefore="+62" nzSize="large">
        <input
          type="text"
          nz-input
          formControlName="telephone"
          nzSize="large"
          placeholder="81234567890"
          aria-label="Telephone Number"
          maxlength="12"
          style="border-radius: 0 8px 8px 0 !important"
          (keydown)="preventNonNumeric($event)"
          (input)="validateInput($event)"
          [nzStatus]="validNumber ? '' : 'error'"
        />
      </nz-input-group>
      <div class="d-flex flex-column">
        <span class="text-danger">
          <img
            *ngIf="errMessNumber !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessNumber }}</span
        >
        <span *ngIf="isChecked" [ngClass]="isWA ? 'text-green' : 'text-danger'">
          <img
            [src]="
              isWA
                ? 'https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg'
                : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/Whatsapp.svg'
            "
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessNumberWA }}
        </span>
      </div>
    </div>
  </form>
  <button
    class="col-12 mt-3 btn"
    [ngClass]="isDisabled ? 'btn-outline-secondary' : 'btn-primary text-white'"
    [disabled]="isDisabled || errMessNumber !== ''"
    (click)="handleConfirm()"
  >
    Confirm
  </button>
</div>
