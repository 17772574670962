<form [action]="">
  <div
    class="d-flex flex-column gap-0 justify-content-center align-items-center px-4 py-5"
  >
    <div class="d-flex justify-content-end w-100 p-2">
      <img
        (click)="handleClose()"
        style="cursor: pointer"
        src="https://storage.googleapis.com/komerce/assets/LP-Komcard/close-circle.svg"
        alt="close"
        width="20px"
      />
    </div>
    <div
      class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center"
    >
      <div class="title text-center">Verification Code</div>
      <div class="text-center">
        Enter the verification code (OTP) that has been sent <br />
        to your {{ typeOTP }} number +62{{ phoneNumber }}
      </div>

      <div
        class="mt-3 w-100 d-flex justify-content-center align-items-center gap-1"
      >
        <otp-phone
          [setting]="settings"
          (keydown)="onKeyDown($event)"
          (onValueChange)="onChange($event)"
        ></otp-phone>
      </div>
      <div
        *ngIf="isErrorMessage === true"
        class="p-2 my-1"
        style="background-color: #ffece9; border-radius: 8px"
      >
        <small class="text-danger">The PIN you entered is incorrect</small>
      </div>
      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
        <button
          class="btn btn-outline-primary w-50"
          (click)="handleChangeMethod($event)"
          [disabled]="seconds !== 0"
        >
          Change Method
        </button>
        <button
          [disabled]="isDisabled"
          (click)="handleConfirm($event)"
          [class]="
            isDisabled
              ? 'btn btn-secondary w-50'
              : 'btn btn-primary w-50 text-white'
          "
        >
          <div class="ps-3 pe-3">Confirm</div>
        </button>
      </div>
      <span
        *ngIf="seconds !== 0 || (minutes !== 0 && !isLimit)"
        class="text-center"
        >Resend in <span *ngIf="minutes !== 0">{{ minutes }} minute </span>
        {{ seconds }} seconds</span
      >
      <span
        *ngIf="seconds === 0 && minutes === 0 && !isLimit"
        class="text-center"
        >Didn't receive the code?
        <span
          class="text-danger"
          style="cursor: pointer"
          (click)="resendVerif()"
        >
          Resend</span
        ></span
      >
      <span
        *ngIf="isLimit"
        class="text-center"
        style="color: #828282 !important"
        >Well, it looks like you're having trouble verifying your OTP, please
        contact us via live chat.</span
      >
    </div>
  </div>
</form>
