<div class="card p-3 d-flex flex-column gap-3 mt-2">
  <nz-skeleton [nzLoading]="loading" [nzActive]="true" class="d-flex flex-column">
      <div class="d-flex align-items-center gap-3">
          <img src="https://storage.googleapis.com/komerce/assets/icons/back.svg" alt="back" (click)="back()" />
          <div class="title">User Acceptance Testing</div>
      </div>
      <div class="mt-4 py-3 px-4" style="background-color: #f8f8f8;">
          <nz-steps [nzCurrent]="current" [nzDirection]="isMediumScreen ? 'vertical' : 'horizontal'" [nzStartIndex]="0" nzSize="small" class="d-flex flex-column flex-lg-row">
            <ng-container *ngFor="let step of stepList; let i = index">
                <nz-step (click)="handleStep(i+1)" [nzTitle]="step" style="cursor: pointer;"></nz-step>
            </ng-container>
          </nz-steps>
      </div>
      <app-uat-destination *ngIf="current === 0"/>
      <app-uat-calculate-shipping *ngIf="current === 1" />
      <app-uat-order *ngIf="current === 2"/>
      <app-uat-pickup *ngIf="current === 3"/>
      <app-uat-print-label *ngIf="current === 4"/>
      <app-uat-history-awb (submitUATFinal)="updateState()" *ngIf="current === 5"/>
  </nz-skeleton>
</div>
