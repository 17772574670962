import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddReferrerComponent } from '../modal-add-referrer/modal-add-referrer.component';
import { ModalAddMobKeyComponent } from '../modal-add-mob-key/modal-add-mob-key.component';
import { BaseService } from 'src/app/services/base.service';
import {
  LIST_IP_ADDRESS,
  LIST_KEY,
  WEBHOOK,
} from 'src/app/constants/endpoint.const';
import { ModalDeleteReferrerComponent } from '../modal-delete-referrer/modal-delete-referrer.component';
import { ModalAddWebhookComponent } from '../modal-add-webhook/modal-add-webhook.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-referrer',
  templateUrl: './profile-referrer.component.html',
  styleUrls: ['./profile-referrer.component.scss'],
})
export class ProfileReferrerComponent implements OnInit {
  destroy$ = new Subject();
  listIPAddress: any[] = [];
  listMobileKey: any[] = [];
  listWebhook: any[] = [];
  loading: boolean = true;

  formGroupWebhook: FormGroup = new FormGroup({
    url: new FormControl('', []),
  });
  isValid: boolean = true;
  isValidURL: boolean = true;
  errIP: string = '';
  errPN: string = '';
  isSame: boolean = true;
  isGetWebhook: boolean = true;

  constructor(
    private message: NzMessageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private baseService: BaseService
  ) {}
  ngOnInit(): void {
    this.isGetWebhook = localStorage.getItem('ro-level') === '3' || localStorage.getItem('role-user') === 'open-api'
    this.getListIP();
    this.getListKey();
    this.getWebhook();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  setRegexIP(event: any) {
    const regex = /^[a-zA-Z0-9._\-\/]+$/;
    return regex.test(event.key);
  }

  setValueRulePlatform() {
    const regex = /^[a-zA-Z0-9:._\-\/]+$/;
    const value = this.formGroupWebhook.get('url')?.value || '';
    const invalidSequenceRegex = /\.\.+/;

    if (value.length === 0) {
      this.isValidURL = true;
      this.errPN = '';
    } else if (value.length < 7 && value.length >= 1) {
      this.isValidURL = false;
      this.errPN = 'URL must be at least 7 characters long';
    } else if (!regex.test(value)) {
      this.isValidURL = false;
      this.errPN = 'Contains not allowed character';
    } else if (invalidSequenceRegex.test(value)) {
      this.isValidURL = false;
      this.errPN = 'Format not supported';
    } else {
      if (value.startsWith('https://') || value.startsWith('http://')) {
        this.isValidURL = true;
        this.errPN = '';
      } else {
        this.isValidURL = false;
        this.errPN = 'URL must start with https:// or http://';
      }
    }

    if (value === this.listWebhook) {
      this.formGroupWebhook.get('url')?.setErrors({ notUnique: true });
      this.isSame = true
    } else {
      this.isSame = false
    }
  }
  setValueRuleWebhook() {
    const regex = /^[a-zA-Z0-9:.\\/]+$/;
    const value = this.formGroupWebhook.get('url')?.value || '';
    const invalidSequenceRegex = /\.\.+/;

    if (value.length === 0) {
      this.isValidURL = true;
      this.errPN = '';
    } else if (value.length < 7 && value.length >= 1) {
      this.isValidURL = false;
      this.errPN = 'URL must be at least 7 characters long';
    } else if (!regex.test(value)) {
      this.isValidURL = false;
      this.errPN = 'Contains not allowed character';
    } else if (invalidSequenceRegex.test(value)) {
      this.isValidURL = false;
      this.errPN = 'Format not supported';
    } else {
      if (value.startsWith('https://') || value.startsWith('http://')) {
        this.isValidURL = true;
        this.errPN = '';
      } else {
        this.isValidURL = false;
        this.errPN = 'URL must start with https:// or http://';
      }
    }

    if (value === this.listWebhook) {
      this.formGroupWebhook.get('url')?.setErrors({ notUnique: true });
      this.isSame = true
    } else {
      this.isSame = false
    }
  }

  submit() {
    const params = {
      webhook_url: this.formGroupWebhook.get('url')?.value,
    };
    this.baseService
      .putData(WEBHOOK, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create(
            'success',
            'Your webhook url has been successfully saved.'
          );
          this.getWebhook();
          this.isSame = true
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }

  addWebhook() {
    const modal = this.modalService.open(ModalAddWebhookComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });

    const modalInstance = modal.componentInstance as ModalAddWebhookComponent;

    modalInstance.updateWebhook.subscribe(() => {
      this.getWebhook();
    });
  }
  addReferrer() {
    const modal = this.modalService.open(ModalAddReferrerComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });

    const modalInstance = modal.componentInstance as ModalAddReferrerComponent;

    modalInstance.addRef.subscribe(() => {
      this.getListIP();
    });
  }
  addMobileKey() {
    const modal = this.modalService.open(ModalAddMobKeyComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
    });

    const modalInstance = modal.componentInstance as ModalAddMobKeyComponent;

    modalInstance.addMobKey.subscribe(() => {
      this.getListKey();
    });
  }

  getWebhook() {
    this.baseService
      .getData(WEBHOOK)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listWebhook = res.data.webhook_url;
          this.formGroupWebhook.get('url')?.setValue(this.listWebhook);
        },
        (err) => {
          this.message.create('error', 'Failed to get Webhook data');
        }
      );
  }
  getListIP() {
    this.baseService
      .getData(LIST_IP_ADDRESS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listIPAddress = res.data;
        },
        (err) => {
          this.message.create('error', 'Failed to get list Api Address');
        }
      );
  }
  getListKey() {
    this.baseService
      .getData(LIST_KEY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listMobileKey = res.data;
        },
        (err) => {
          this.message.create('error', 'Failed to get list Mobile Key Android');
        }
      );
  }

  deleteReferrer(e: number, d: string) {
    const modal = this.modalService.open(ModalDeleteReferrerComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    const modalInstance =
      modal.componentInstance as ModalDeleteReferrerComponent;

    modal.componentInstance.idRef = e;
    modal.componentInstance.typeDevice = d;

    modalInstance.deleteRef.subscribe(() => {
      this.getListKey();
      this.getListIP();
    });
  }

  deviceType(e: string) {
    if (e === 'android' || e === 'Android') return 'Android';
    else return 'iOS';
  }
  classdeviceType(e: string) {
    if (e === 'android' || e === 'Android') return 'android';
    else return 'ios';
  }

  keyCer(e: string) {
    const separatedVal = e.split(';');

    if (separatedVal[0] === '') {
      return '-';
    } else {
      return separatedVal[0];
    }
  }
  packName(e: string) {
    const separatedVal = e.split(';');
    if (separatedVal[1] === '') {
      return '-';
    } else {
      return separatedVal[1];
    }
  }
}
