import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { PinModel } from 'src/app/models/pin.model';
import { ModalChangeNumberComponent } from '../modal-change-number/modal-change-number.component';
import { BaseService } from 'src/app/services/base.service';
import {
  REQUEST_OTP,
  UPDATE_PHONE,
  VERIFY_OTP,
} from 'src/app/constants/endpoint.const';
import { ModalInputNumberComponent } from '../modal-input-number/modal-input-number.component';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-verif-otp',
  templateUrl: './modal-verif-otp.component.html',
  styleUrls: ['./modal-verif-otp.component.scss'],
})
export class ModalVerifOTPComponent implements OnInit {
  @Input() phoneNumber: string = '';
  @Input() tokenVerif: string = '';
  @Input() isConfirm: boolean = false;
  pin!: PinModel;
  settings: any = {
    length: 6,
    numbersOnly: true,
  };
  @Input() otpParams: any = {
    pin: '',
  };
  otp: string = '';
  isErrorMessage: boolean = false;
  destroy$ = new Subject();
  isDisabled: boolean = true;
  url: string = '';
  @Input() typeOTP: string = '';

  @Input() nextRequestAt: string = '';
  @Input() remainingTime: number = 0;
  intervalId: any;
  minutes: number = 0;
  seconds: number = 0;
  isLimit: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private BaseService: BaseService,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {
    const nextRequestTime = moment(this.nextRequestAt);
    const currentTime = moment();

    this.remainingTime = nextRequestTime.diff(currentTime, 'seconds');

    this.startCountdown();
  }

  startCountdown(): void {
    this.intervalId = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
        this.minutes = Math.floor(this.remainingTime / 60);
        this.seconds = this.remainingTime % 60;
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }
  handleClose() {
    this.activeModal.close();
  }
  handleChangeMethod(e: any): void {
    this.activeModal.close();
    if (this.isConfirm) {
      const modal = this.modalService.open(ModalChangeNumberComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });

      modal.componentInstance.phoneNumber = this.phoneNumber;
      modal.componentInstance.isConfirm = true;
    } else {
      const modal = this.modalService.open(ModalChangeNumberComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });

      modal.componentInstance.phoneNumber = this.phoneNumber;
      modal.componentInstance.isConfirm = false;
    }
  }
  resendVerif() {
    const params = this.isConfirm
      ? {
          purpose: 'phone',
          type_otp: this.typeOTP,
          phone: this.phoneNumber,
          token: localStorage.getItem('token-otp-first') || '',
        }
      : {
          purpose: 'phone',
          type_otp: this.typeOTP,
        };

    this.BaseService.postData(REQUEST_OTP, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (!this.isConfirm) {
            localStorage.setItem(
              'token-otp-first',
              btoa(res.data.token + '%phone')
            );
          }
          this.tokenVerif = btoa(res.data.token + '%phone');
          const nextRequestTime = moment(res.data.next_request_at);
          const currentTime = moment();

          this.remainingTime = nextRequestTime.diff(currentTime, 'seconds');

          this.startCountdown();
        },
        (err) => {
          if (err.error.meta.message === 'reached limit request otp') {
            this.isLimit = true;
            this.message.create(
              'error',
              "Well, it looks like you're having trouble verifying your OTP, please contact us via live chat."
            );
          } else if (err.error.meta.message === 'otp not valid') {
            this.message.create(
              'error',
              "Incorrect OTP code, let's check again"
            );
          } else {
            this.message.create(
              'error',
              'Failed to request OTP, please try again'
            );
          }
        }
      );
  }

  onChange(e: string) {
    this.otp = e;
    if (e.length === 6) {
      this.isDisabled = false;
    }
  }
  handleConfirm(e: any) {
    const params = {
      token: this.tokenVerif,
      otp: this.otp,
    };
    this.BaseService.postData(VERIFY_OTP, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (this.isConfirm === false) {
            this.activeModal.close();
            const modal = this.modalService.open(ModalInputNumberComponent, {
              size: 'md',
              backdrop: 'static',
              keyboard: false,
              centered: true,
            });
          } else {
            this.updatePhone();
          }
        },
        (err) => {
          if (err.error.meta.message === 'otp not valid') {
            this.message.create(
              'error',
              "Incorrect OTP code, let's check again"
            );
          } else {
            this.message.create(
              'error',
              'Failed to verify OTP, please try again'
            );
          }
        }
      );
  }

  updatePhone() {
    const formdata = new FormData();
    formdata.append('phone_number', this.phoneNumber);
    this.BaseService.putData(UPDATE_PHONE, formdata)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create(
            'success',
            'Your phone number has been successfully changed'
          );
          setTimeout(() => {
            this.activeModal.close();
            window.location.reload();
          }, 1000);
        },
        (err) => {
          this.message.create(
            'error',
            'Failed to update phone number, please try again'
          );
        }
      );
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.isErrorMessage = false;
      this.isDisabled = true;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
