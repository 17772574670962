import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-escalation-input',
  standalone: false,
  templateUrl: './business-escalation-input.component.html',
  styleUrl: './business-escalation-input.component.scss',
})
export class BusinessEscalationInputComponent {
  businessForm!: FormGroup;
  current: number = 2;
  @Input() slaDataInput: any;
  @Output() businessData = new EventEmitter<any>();
  [key: string]: any;
  validOName: boolean = true;
  errMessOName: string = '';
  validFName: boolean = true;
  errMessFName: string = '';
  validTName: boolean = true;
  errMessTName: string = '';

  validTJob: boolean = true;
  errMessTJob: string = '';
  validFJob: boolean = true;
  errMessFJob: string = '';
  validOJob: boolean = true;
  errMessOJob: string = '';

  validONumber: boolean = true;
  errMessONumber: string = '';
  validFNumber: boolean = true;
  errMessFNumber: string = '';
  validTNumber: boolean = true;
  errMessTNumber: string = '';

  validOEmail: boolean = true;
  errMessOEmail: string = '';
  validTEmail: boolean = true;
  errMessTEmail: string = '';
  validFEmail: boolean = true;
  errMessFEmail: string = '';

  constructor(private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.initForm();

    if (this.businessForm) {
      this.businessForm.patchValue(this.slaDataInput);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['slaDataInput'] && changes['slaDataInput'].currentValue) {
      const slaData = changes['slaDataInput'].currentValue;

      if (this.businessForm) {
        this.businessForm.patchValue(slaData);
      }
    }
  }

  initForm() {
    this.businessForm = this.fb.group({
      operational_name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      operational_job_title: [
        '',
        [Validators.required, Validators.maxLength(100)],
      ],
      operational_phone_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*\\.?[0-9]*$')],
      ],
      operational_email: ['', [Validators.required, Validators.email]],

      finance_name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      finance_job_title: ['', [Validators.required, Validators.maxLength(100)]],
      finance_phone_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*\\.?[0-9]*$')],
      ],
      finance_email: ['', [Validators.required, Validators.email]],

      technology_name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      technology_job_title: [
        '',
        [Validators.required, Validators.maxLength(100)],
      ],
      technology_phone_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*\\.?[0-9]*$')],
      ],
      technology_email: ['', [Validators.required, Validators.email]],
    });
  }

  validateInput(event: any, fieldType: 'OName' | 'FName' | 'TName' | 'OJob' | 'FJob' | 'TJob') {
    const input = event.target as HTMLInputElement;
    const minLength = 3;
    const maxLength = 50;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;
    const fieldLabel = fieldType.includes('Name') ? 'Name' : 'Job title';

    if (input.value === '') {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `${fieldLabel} must be filled in`;
    } else if (input.value.length < minLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Minimum ${minLength} characters`;
    } else if (input.value.length > maxLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Maximum ${maxLength} characters`;
    } else {
      this[fieldValidKey] = true;
      this[fieldErrorKey] = '';
    }
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInputNumber(event: Event, fieldType: 'ONumber' | 'FNumber' | 'TNumber'): void {
    const input = event.target as HTMLInputElement;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;

    if (input.value.length === 0) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = 'Phone number must be filled';
    } else if (!/^\d+$/.test(input.value)) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = 'Must be a number';
    } else if (!input.value.startsWith('8')) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = 'Phone number must start with number 8';
    } else if (input.value.length < 9) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = 'Minimum 9 digits';
    } else {
      this[fieldValidKey] = true;
      this[fieldErrorKey] = '';
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }

  validateEmail(event: any, fieldType: 'OEmail' | 'FEmail' | 'TEmail') {
    const input = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;

    if (input.value === '') {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = 'Email must be filled in';
    } else {
      if (input.value.length < 10) {
        this[fieldErrorKey] = 'Minimum 10 characters';
        this[fieldValidKey] = false;
      } else if (input.value.length > 50) {
        this[fieldErrorKey] = 'Maximum 50 characters';
        this[fieldValidKey] = false;
      } else if (!regex.test(input.value)) {
        this[fieldValidKey] = false;
        this[fieldErrorKey] = 'Email must be a valid email address';
      } else {
        const atIndex = input.value.indexOf('@');
        const lastDotIndex = input.value.lastIndexOf('.');
        const dotBeforeAt = input.value.substring(0, atIndex).includes('.');
        const dotAfterAt = input.value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === input.value.length - 1 ||
          input.value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this[fieldErrorKey] = 'Email must be a valid email address';
          this[fieldValidKey] = false;
        } else {
          this[fieldValidKey] = true;
          this[fieldErrorKey] = '';
        }
      }
    }
  }

  onBack(): void {
    this.router.navigate(['/document/sla-data-input'], {
      queryParams: { step: 2 },
    });
  }
  onSubmit(): void {
    const mode = localStorage.getItem('mode-sla-input')
    if (this.businessForm.valid) {
        this.current = 3;
        this.businessData.emit(this.businessForm.value);
        if (mode === 'create') {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 4 },
          });
        } else {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 4, mode: mode },
          });
        }
    } else {
      console.log('Form is invalid!');
    }
  }

  get isFormInvalid(): boolean {
    return (
      this.businessForm.invalid ||
      this.errMessTEmail !== '' ||
      this.errMessFEmail !== '' ||
      this.errMessOEmail !== '' ||
      this.errMessTName !== '' ||
      this.errMessOName !== '' ||
      this.errMessFName !== '' ||
      this.errMessONumber !== '' ||
      this.errMessFNumber !== '' ||
      this.errMessTNumber !== '' ||
      this.errMessTEmail !== '' ||
      this.errMessOEmail !== '' ||
      this.errMessFEmail !== ''
    );
  }
}
