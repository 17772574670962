<div class="card p-3 d-flex flex-column gap-3 mt-2">
  <nz-skeleton [nzLoading]="loading" [nzActive]="true">
    <div class="title">PIN Settings</div>
    <div class="d-flex flex-row gap-3 align-items-center">
      <div>
        <img src="assets/icons/black/shield.svg" alt="">
      </div>
      <div class="d-flex flex-column flex-grow-1">
          <div class="sub-title">PIN</div>
          <div>Make sure your PIN is safe because it is used to withdraw balances and manage bank accounts.</div>
      </div>
      <div *ngIf="profile.pin_status === false" class="d-flex flex-row flex-grow-0 gap-1 align-items-center">
        <button class="btn btn-primary text-white" (click)="open(buatPIN)">Create PIN</button>
      </div>
      <div *ngIf="profile.pin_status === true" class="d-flex flex-row flex-grow-0 gap-1 align-items-center">
        <button class="btn btn-primary text-white" (click)="open(verifPIN)">Change PIN</button>
        <button class="btn btn-link text-primary" (click)="lupaPinClik($event)">Forgot Pin</button>
      </div>
    </div>
  </nz-skeleton>
</div>
<ng-template #buatPIN let-c="close" let-d="dismiss">
  <div class="d-flex flex-column gap-0 justify-content-center align-items-center">
    <div class="d-flex justify-content-end w-100 p-1">
      <img (click)="close(buatPIN)" style="cursor: pointer;" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg" alt="">
    </div>
    <div class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center">
      <div class="title text-center">Create PIN</div>
      <div class="text-center">Enter your PIN</div>

      <div class="mt-3 w-100 d-flex justify-content-center align-items-center gap-1">
        <otp [setting]="settings" (keydown)="onKeyDown($event)" (onValueChange)="onChange($event)"></otp>
      </div>
      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
          <button class="btn btn-outline-primary w-50" (click)="close(buatPIN)">Cancel</button>
        <button [disabled]="isDisabled" (click)="handleCreate($event)"
          [class]="isDisabled ? 'btn btn-secondary w-50' : 'btn btn-primary w-50 text-white'">
          <div class="ps-3 pe-3">Confirm</div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #verifPIN let-c="close" let-d="dismiss">
  <div class="d-flex flex-column gap-0 justify-content-center align-items-center">
    <div class="d-flex justify-content-end w-100 p-1">
      <img (click)="close(verifPIN)" style="cursor: pointer;" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg" alt="">
    </div>
    <div class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center">
      <div class="title text-center">Change PIN</div>
      <div class="text-center">Enter your Old PIN</div>

      <div class="mt-3 w-100 d-flex justify-content-center align-items-center gap-1">
        <otp [setting]="settings" (keydown)="onKeyDown($event)" (onValueChange)="onChange($event)"></otp>
      </div>
      <div *ngIf="isErrorMessage === true" class="p-2 my-1" style="background-color: #FFECE9;border-radius: 8px;">
        <small class="text-danger">The PIN you entered is incorrect</small>
      </div>
      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
          <button class="btn btn-outline-primary w-50" (click)="close(verifPIN)">Cancel</button>
        <button [disabled]="isDisabled" (click)="handleConfirm($event)"
          [class]="isDisabled ? 'btn btn-secondary w-50' : 'btn btn-primary w-50 text-white'">
          <div class="ps-3 pe-3">Next</div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #changePIN let-c="close" let-d="dismiss">
  <div class="d-flex flex-column gap-0 justify-content-center align-items-center">
    <div class="d-flex justify-content-end w-100 p-1">
      <img (click)="close(changePIN)" style="cursor: pointer;" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg" alt="">
    </div>
    <div class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center">
      <div class="title text-center">Change PIN</div>
      <div class="text-center">Enter Your New PIN</div>

      <div class="mt-3 w-100 d-flex justify-content-center align-items-center gap-1">
        <otp [setting]="settings" (keydown)="onKeyDown($event)" (onValueChange)="onChange($event)"></otp>
      </div>
      <div *ngIf="isErrorMessage === true" class="p-2 my-1" style="background-color: #FFECE9;border-radius: 8px;">
        <small class="text-danger">The PIN you entered is incorrect</small>
      </div>
      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
          <button class="btn btn-outline-primary w-50" (click)="close(changePIN)">Cancel</button>
        <button [disabled]="isDisabled" (click)="handleUpdate($event)"
          [class]="isDisabled ? 'btn btn-secondary w-50' : 'btn btn-primary w-50 text-white'">
          <div class="ps-3 pe-3">Confirm</div>
        </button>
      </div>
    </div>
  </div>
</ng-template>