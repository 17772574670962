import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { DOC_STATE, DOC_UPLOAD } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

interface DocumentRow {
  id: number;
  title: string;
  isRequired: boolean;
  subtitle?: string;
  key: string;
}

@Component({
  selector: 'app-sla-submission',
  standalone: false,
  templateUrl: './sla-submission.component.html',
  styleUrl: './sla-submission.component.scss',
})
export class SlaSubmissionComponent {
  destroy$ = new Subject<void>();
  loading: boolean = true;
  documents: DocumentRow[] = [
    {
      id: 1,
      title:
        'Service Agreement signed by the client and stamped by the company',
      isRequired: true,
      key: 'sla_file',
    },
    {
      id: 2,
      title: 'Deed of Establishment and Amendment',
      isRequired: true,
      key: 'amandement_business_file',
    },
    {
      id: 3,
      title: 'Business/Personal NPWP',
      isRequired: false,
      key: 'npwp_file',
    },
    {
      id: 4,
      title: 'Business Identification Number (NIB)',
      isRequired: false,
      subtitle: '(for Business Entity)',
      key: 'nib_file',
    },
    {
      id: 5,
      title:
        'Business Entity Bank Account Number / Copy of Bank Account Statement',
      isRequired: false,
      key: 'bank_account_number_file',
    },
    {
      id: 6,
      title:
        'ID card (KTP) of the Director / Representative in charge of signing',
      isRequired: true,
      key: 'id_card_file',
    },
    {
      id: 7,
      title: 'Power of Attorney',
      isRequired: false,
      subtitle: '(for Directors unable to sign)',
      key: 'power_of_attorney_file',
    },
    {
      id: 8,
      title: 'Statement Letter for Non-Business Entity Bank Accounts',
      isRequired: false,
      key: 'statement_letter_file',
    },
    {
      id: 9,
      title: 'ID card (KTP) of the PIC for the Dashboard / API account',
      isRequired: false,
      key: 'pic_dashboard',
    },
  ];
  filename: string = 'Lampirkan File';
  @ViewChild('fileupload') fileUploadRef!: ElementRef;
  SlaFiles!: FormGroup;
  slaFile: any[] = [];
  abfFile: any[] = [];
  npwpFile: any[] = [];
  nibFile: any[] = [];
  banFile: any[] = [];
  idFile: any[] = [];
  poaFile: any[] = [];
  slFile: any[] = [];
  picFile: any[] = [];

  constructor(
    private router: Router,
    private baseService: BaseService,
    private fb: FormBuilder,
    private message: NzMessageService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getFiles();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  initForm(): void {
    this.SlaFiles = this.fb.group(
      this.documents.reduce(
        (controls, doc) => ({
          ...controls,
          [doc.key]: [null, doc.isRequired ? Validators.required : null],
          [doc.key + '_note']: [
            null,
            doc.isRequired
              ? [Validators.required, Validators.minLength(5), Validators.maxLength(250)]
              : null,
          ],
        }
        ),
        {}
      )
    );
  }  

  handleBack(): void {
    this.router.navigate(['/document']);
  }

  handleNotes(event: any, key: string): void {
    const value = event.target.value as HTMLElement;
  }

  triggerFileInput(key: string): void {
    const fileInput: HTMLInputElement = document.getElementById('fileupload_' + key) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  fileUploadMap: Map<string, File> = new Map();

  handleFileUpload(event: any, key: string): void {
    const files: File[] = Array.from(event.target.files);
    const allowedFormats = ['pdf'];
    const maxFileSize = 500 * 1024;
  
    const file = files[0];
    if (!file) return;
  
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (file.size > maxFileSize) {
      this.message.error('File size exceeds limit');
      return;
    }
    if (!allowedFormats.includes(fileExtension as string)) {
      this.message.error('Invalid file format');
      return;
    }
    switch (key) {
      case 'sla_file':
        this.slaFile = [file];
        break;
      case 'amandement_business_file':
        this.abfFile = [file];
        break;
      case 'npwp_file':
        this.npwpFile = [file];
        break;
      case 'nib_file':
        this.nibFile = [file];
        break;
      case 'bank_account_number_file':
        this.banFile = [file];
        break;
      case 'id_card_file':
        this.idFile = [file];
        break;
      case 'power_of_attorney_file':
        this.poaFile = [file];
        break;
      case 'statement_letter_file':
        this.slFile = [file];
        break;
      case 'pic_dashboard':
        this.picFile = [file];
        break;
      default:
        console.error('Unknown key:', key);
        return;
    }
  }
  
  getFiles() {
    this.baseService.getData(DOC_UPLOAD + '/service-level-agreement')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          res.data.forEach((file: any) => {
            const targetArray = this.getTargetFileArray(file.document_type);
            if (targetArray) {
              targetArray[0] = { 
                url: file.uploaded_file_path, 
                name: file.file_name, 
                notes: file.notes 
              };
  
              this.SlaFiles.patchValue({
                [file.document_type + '_note']: file.notes,
              });
            }
          });
        },
        error: (err) => {
          console.error(err);
        }
      });
  }
  
  
  
  private getTargetFileArray(documentType: string): any[] | null {
    switch (documentType) {
      case 'sla_file': return this.slaFile;
      case 'amandement_business_file': return this.abfFile;
      case 'npwp_file': return this.npwpFile;
      case 'nib_file': return this.nibFile;
      case 'bank_account_number_file': return this.banFile;
      case 'id_card_file': return this.idFile;
      case 'power_of_attorney_file': return this.poaFile;
      case 'statement_letter_file': return this.slFile;
      case 'pic_dashboard': return this.picFile;
      default: return null;
    }
  }
  
  resetFileInput(docKey: string): void {
    const inputElement = document.getElementById('fileupload_' + docKey) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
  }
  
  deleteFile(key: string): void {
    this.baseService.deleteData(DOC_UPLOAD + '/' + key)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.resetFileInput(key);

          switch (key) {
            case 'sla_file':
              this.slaFile = [];
              break;
            case 'amandement_business_file':
              this.abfFile = [];
              break;
            case 'npwp_file':
              this.npwpFile = [];
              break;
            case 'nib_file':
              this.nibFile = [];
              break;
            case 'bank_account_number_file':
              this.banFile = [];
              break;
            case 'id_card_file':
              this.idFile = [];
              break;
            case 'power_of_attorney_file':
              this.poaFile = [];
              break;
            case 'statement_letter_file':
              this.slFile = [];
              break;
            case 'pic_dashboard':
              this.picFile = [];
              break;
            default:
              console.error('Unknown key:', key);
              return;
          }

          this.SlaFiles.patchValue({
            [key]: null,
            [key + '_note']: null,
          });

          this.message.success('Document deleted successfully');
        },
        (err) => {
          console.error(err);
        }
      );
  }
    

  getUploadedFileName(key: string): string {
    const targetArray = this.getTargetFileArray(key);
    return targetArray && targetArray[0]?.name ? targetArray[0].name : '';
  }
    

  uploadDocument(documentType: string, fileArray: any[]): void {
    if (fileArray.length === 0 || !fileArray[0].url && !fileArray[0].name) return;
  
    const formdata = new FormData();
    formdata.append('document_type', documentType);
    formdata.append('notes', this.SlaFiles.get(documentType + '_note')?.value || '');
  
    if (fileArray[0] instanceof File) {
      formdata.append('file', fileArray[0]);
    }
  
    this.baseService.postData(DOC_UPLOAD, formdata)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.success('Document uploaded successfully');
          this.updateState();
        },
        (err) => {
          console.error(err);
        }
      );
  }
  
  
  onSubmit(): void {
    if (
      this.slaFile.length === 0 ||
      this.abfFile.length === 0 ||
      this.idFile.length === 0
    ) {
      this.message.error('Please upload all required documents.');
      return;
    } else {
      this.documents.forEach(doc => {
        const targetArray = this.getTargetFileArray(doc.key);
        if (targetArray) {
          this.uploadDocument(doc.key, targetArray);
        }
      });
      this.getFiles()
    }
  }
  

  updateState(): void {
    const params = {
          document_type: 'SLA',
          state: 4,
        }
        this.baseService.putData(DOC_STATE, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.message.success('Document uploaded successfully');
            this.router.navigate(['/document']);
          },
          (err) => {
            console.error(err)
          }
        )
  }
}
