import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { TEST_CASE, UAT_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-destination',
  standalone: false,
  templateUrl: './uat-destination.component.html',
  styleUrl: './uat-destination.component.scss'
})
export class UatDestinationComponent {
  destroy$: Subject<void> = new Subject<void>();
  uatDestination: any[] = [];
  destinationForm!: FormGroup;
  current: number = 1;

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private router: Router,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
    this.getUatTestCase();
    this.getUatData();
    this.initForm();
  }

  initForm() {
    this.destinationForm = this.fb.group({
      curl_destination: [null, Validators.required],
      result_destination: [null, Validators.required],
    });
  }

  next(): void {
    this.submitUat()
    this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current + 1 } });
  }
  previus(): void {
    if (this.current > 1) {
      this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current - 1 } });
    }
  }

  getUatTestCase() {
    this.baseService.getData(TEST_CASE + '/destination')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.uatDestination = res.data;
      }, (err) => { });
  }

  getUatData() {
    this.baseService.getData(UAT_DATA + '/destination')
    .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.destinationForm.patchValue({
          curl_destination: res.data[0].curl,
          result_destination: res.data[0].result
        })
        this.uatDestination[0].notes = res.data[0].notes
      }, (err) => {
        console.error(err.error.data.errors)
      });
  }

  submitUat() {
    const input = [
      {
        test_case_id: this.uatDestination[0].id,
        curl: this.destinationForm.get('curl_destination')?.value,
        result: this.destinationForm.get('result_destination')?.value,
      }
    ]
    this.baseService.postData(UAT_DATA, { input })
    .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        localStorage.setItem('uat-destination', 'complete')
      }, (err) => {
        console.error(err.error.data.errors)
      });
  }
}
