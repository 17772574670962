import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-alert-switch',
  standalone: false,
  templateUrl: './modal-alert-switch.component.html',
  styleUrl: './modal-alert-switch.component.scss'
})
export class ModalAlertSwitchComponent {
  @Input() isVisibleSwitch = false;
  @Output() isVisibleSwitchEvent = new EventEmitter<boolean>();

  constructor(
    private route: Router
  ) {}
  
  handleCancel(): void {
    this.isVisibleSwitch = false;
    this.isVisibleSwitchEvent.emit(this.isVisibleSwitch);
  }
  handleConfirm(): void {
    this.isVisibleSwitch = false;
    this.isVisibleSwitchEvent.emit(this.isVisibleSwitch);
    this.route.navigate(['/document']);
  }
}
