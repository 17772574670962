<nz-modal [(nzVisible)]="isVisibleAlert" nzCentered [nzFooter]="null" nzClosable="false">
      <ng-container *nzModalContent>
        <div class="d-flex flex-column align-items-center py-4">
          <img src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png" alt="alert" width="100px" />
          <h5 class="mt-3 text-bold text-capitalize">Phone Number Missing!</h5>
          <span class="text-center">We noticed that your account doesn’t have a registered phone number. Add it now to enhance security.</span>
          <button class="btn btn-primary text-white w-100 mt-3" (click)="handleOk()">Add Phone Number</button>
        </div>
      </ng-container>
</nz-modal>

<app-modal-add-phone [isVisibleInputNumber]="isAddNumber"></app-modal-add-phone>
