import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { PengaturanComponent } from './pengaturan.component';
import { PinComponent } from './pages/pin/pin.component';
import { PengaturanRoutingModule } from './pengaturan-routing.module';
import { ModalLupaPinComponent } from './components/modal-lupa-pin/modal-lupa-pin.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './pages/profile/profile.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { ModalBuatPinComponent } from './components/modal-buat-pin/modal-buat-pin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AntDModule } from 'src/app/modules/shared/antd.module';
import { ChangePasswordComponent } from './pages/profile/pages/change-password/change-password.component';
import { ModalChangeNumberComponent } from './pages/profile/components/modal-change-number/modal-change-number.component';
import { ModalVerifOTPComponent } from './pages/profile/components/modal-verif-otp/modal-verif-otp.component';
import { ModalDeletePhotoComponent } from './pages/profile/components/modal-delete-photo/modal-delete-photo.component';
import { ProfileGeneralComponent } from './pages/profile/components/profile-general/profile-general.component';
import { ProfileApiKeyComponent } from './pages/profile/components/profile-api-key/profile-api-key.component';
import { ModalViewKeyComponent } from './pages/profile/components/modal-view-key/modal-view-key.component';
import { ProfileReferrerComponent } from './pages/profile/components/profile-referrer/profile-referrer.component';
import { ModalAddReferrerComponent } from './pages/profile/components/modal-add-referrer/modal-add-referrer.component';
import { ModalAddMobKeyComponent } from './pages/profile/components/modal-add-mob-key/modal-add-mob-key.component';
import { ModalInputNumberComponent } from './pages/profile/components/modal-input-number/modal-input-number.component';
import { ModalDeleteReferrerComponent } from './pages/profile/components/modal-delete-referrer/modal-delete-referrer.component';
import localeDe from '@angular/common/locales/de';
import { ModalAddWebhookComponent } from './pages/profile/components/modal-add-webhook/modal-add-webhook.component';
import { DocumentComponent } from './pages/document/document.component';
import { SlaDataInputComponent } from './pages/document/pages/sla-data-input/sla-data-input.component';
import { BusinessInformationInputComponent } from './pages/document/pages/sla-data-input/components/business-information-input/business-information-input.component';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { BusinessQuestionaireInputComponent } from './pages/document/pages/sla-data-input/components/business-questionaire-input/business-questionaire-input.component';
import { BusinessEscalationInputComponent } from './pages/document/pages/sla-data-input/components/business-escalation-input/business-escalation-input.component';
import { ServiceUsageInputComponent } from './pages/document/pages/sla-data-input/components/service-usage-input/service-usage-input.component';
import { SlaSubmissionComponent } from './pages/document/pages/sla-submission/sla-submission.component';
import { CaDataInputComponent } from './pages/document/pages/ca-data-input/ca-data-input.component';
import { CaSubmissionComponent } from './pages/document/pages/ca-submission/ca-submission.component';
import { UatInputComponent } from './pages/document/pages/uat-input/uat-input.component';
import { UatDestinationComponent } from './pages/document/pages/uat-input/components/uat-destination/uat-destination.component';
import { UatCalculateShippingComponent } from './pages/document/pages/uat-input/components/uat-calculate-shipping/uat-calculate-shipping.component';
import { UatOrderComponent } from './pages/document/pages/uat-input/components/uat-order/uat-order.component';
import { UatPickupComponent } from './pages/document/pages/uat-input/components/uat-pickup/uat-pickup.component';
import { UatPrintLabelComponent } from './pages/document/pages/uat-input/components/uat-print-label/uat-print-label.component';
import { UatHistoryAwbComponent } from './pages/document/pages/uat-input/components/uat-history-awb/uat-history-awb.component';
registerLocaleData(localeDe);

@NgModule({
  declarations: [
    PengaturanComponent,
    PinComponent,
    ModalLupaPinComponent,
    ProfileComponent,
    ModalBuatPinComponent,
    ChangePasswordComponent,
    ModalChangeNumberComponent,
    ModalVerifOTPComponent,
    ModalDeletePhotoComponent,
    ProfileGeneralComponent,
    ProfileApiKeyComponent,
    ModalViewKeyComponent,
    ProfileReferrerComponent,
    ModalAddReferrerComponent,
    ModalAddMobKeyComponent,
    ModalInputNumberComponent,
    ModalDeleteReferrerComponent,
    ModalAddWebhookComponent,
    DocumentComponent,
    SlaDataInputComponent,
    BusinessInformationInputComponent,
    BusinessQuestionaireInputComponent,
    BusinessEscalationInputComponent,
    ServiceUsageInputComponent,
    SlaSubmissionComponent,
    CaDataInputComponent,
    CaSubmissionComponent,
    UatInputComponent,
    UatDestinationComponent,
    UatCalculateShippingComponent,
    UatOrderComponent,
    UatPickupComponent,
    UatPrintLabelComponent,
    UatHistoryAwbComponent,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'de-DE' }],
  imports: [
    CommonModule,
    PengaturanRoutingModule,
    NgbModalModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AntDModule,
    NgbModule,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    NgSelectModule,
  ],
})
export class PengaturanModule {}
