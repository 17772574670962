<div class="col-12 mt-2 d-flex p-2 flex-column">
  <nz-card *ngIf="isGetWebhook" class="rounded p-4 mb-3" nzHoverable>
    <nz-skeleton
      *ngIf="loading"
      [nzLoading]="loading"
      [nzActive]="true"
    ></nz-skeleton>
    <div *ngIf="!loading" class="d-flex flex-column">
      <h6>Webhook</h6>
      <span
        >A webhook is an automatic link between apps, sending data instantly
        when a specific event occurs. It enables real-time updates without
        manual checks</span
      >
    </div>
    <div *ngIf="!loading" class="mt-3">
      <nz-list *ngIf="!listWebhook" [nzDataSource]="[]"></nz-list>
      <form *ngIf="listWebhook" [formGroup]="formGroupWebhook">
        <div
          class="form-group mt-3 d-flex flex-column flex-xl-row align-items-center col-12"
        >
          <label class="col-lg-2 col-sm-12" for="url">Webhook URL</label>
          <div class="col-lg-6 col-sm-12 d-flex flex-column">
            <input
              nz-input
              nzSize="large"
              formControlName="url"
              placeholder="https://yourwebhook.url/webhook"
              (keydown.enter)="$event.preventDefault()"
              (input)="setValueRuleWebhook()"
              nzSize="large"
              [nzStatus]="isValidURL ? '' : 'error'"
            />
          </div>
        </div>
        <div class="mt-1 d-flex align-items-center col-12">
          <div class="col-lg-2 col-sm-0"></div>
          <span class="text-danger">
            <img
              *ngIf="errPN !== ''"
              src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errPN }}</span
          >
        </div>
      </form>
      <div class="mt-3">
        <button
          *ngIf="!listWebhook"
          class="btn btn-primary text-white"
          (click)="addWebhook()"
        >
          Add Webhook URL
        </button>
        <button
          *ngIf="listWebhook"
          class="btn"
          [ngClass]="
            errIP !== '' ||
            errPN !== '' ||
            formGroupWebhook.invalid ||
            isSame
              ? 'btn-outline-secondary'
              : 'btn-primary text-white'
          "
          [disabled]="
            errIP !== '' ||
            errPN !== '' ||
            formGroupWebhook.invalid ||
            isSame
          "
          (click)="submit()"
        >
          Edit Webhook URL
        </button>
      </div>
    </div>
  </nz-card>
  <nz-card class="rounded p-4" nzHoverable>
    <nz-skeleton
      *ngIf="loading"
      [nzLoading]="loading"
      [nzActive]="true"
    ></nz-skeleton>
    <div *ngIf="!loading" class="d-flex flex-column">
      <h6>Whitelist or Referre Acces API</h6>
      <span
        >The data below is a Whitelist or referrer list of IPs that are
        registered in order to use / access the Integration API</span
      >
    </div>
    <div *ngIf="!loading" class="mt-3">
      <nz-table
        #basicTable
        [nzData]="listIPAddress"
        [nzFrontPagination]="false"
        [nzScroll]="{ x: '1000px' }"
      >
        <thead>
          <tr>
            <th style="width: 50px !important"></th>
            <th style="width: 350px !important">IP Address</th>
            <th style="width: 350px !important">Platform Name</th>
            <th>Added</th>
            <th style="width: 100px !important"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let items of listIPAddress; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ items.ip_address }}</td>
            <td>{{ items.platform_name }}</td>
            <td>{{ items.updated_at | date : "dd/MM/yyyy" }}</td>
            <td>
              <button class="btn p-0" (click)="deleteReferrer(items.id, '')">
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="delete"
                  width="20px"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div class="mt-3">
        <button class="btn btn-primary text-white" (click)="addReferrer()">
          Add Referrer
        </button>
      </div>
    </div>
  </nz-card>
  <nz-card class="rounded p-4 mt-3" nzHoverable>
    <nz-skeleton
      *ngIf="loading"
      [nzLoading]="loading"
      [nzActive]="true"
    ></nz-skeleton>
    <div *ngIf="!loading" class="d-flex flex-column">
      <h6>Mobile Key (Android and iOS)</h6>
      <span
        >Secure your API Key for Android and iOS applications. Every request
        from an Android or iOS device will be verified by RajaOngkir or
        Collaborator OpenAPI to ensure it comes from the correct application,
        matching the certificate (for Android) and package name listed below.
        You can find sample code to obtain the certificate and package name
        here. Enter one certificate and package name :</span
      >
    </div>
    <div *ngIf="!loading" class="mt-3">
      <nz-table
        #basicTable
        [nzData]="listMobileKey"
        [nzFrontPagination]="false"
        [nzScroll]="{ x: '1000px' }"
      >
        <thead>
          <tr>
            <th style="width: 50px !important"></th>
            <th style="width: 350px !important">Key Certificate</th>
            <th style="width: 350px !important">Package Name</th>
            <th style="width: 150px !important">Added</th>
            <th style="width: 150px !important">Device</th>
            <th style="width: 100px !important"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let items of listMobileKey; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ items.certificate || "-" }}</td>
            <td>{{ items.package_name || "-" }}</td>
            <td>{{ items.created_at | date : "dd/MM/YYYY" }}</td>
            <td>
              <span [ngClass]="classdeviceType(items.type)" class="rounded">
                {{ deviceType(items.type) }}
              </span>
            </td>
            <td>
              <button
                class="btn p-0"
                (click)="deleteReferrer(items.id, items.type)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="delete"
                  width="20px"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div class="mt-3">
        <button class="btn btn-primary text-white" (click)="addMobileKey()">
          Add Key
        </button>
      </div>
    </div>
  </nz-card>
</div>
