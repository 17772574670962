<div class="login-container">
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div class="d-flex flex-column justify-content-center gap-3">
        <div class="d-flex justify-content-center">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <form [formGroup]="formGroup">
            <div
              class="px-md-5 px-3 py-3 m-3 card-glass shadow d-flex flex-column gap-3 col-12"
            >
              <div class="text-center desc">
                <div class="title mt-3">Login</div>
                <div>Please login to start using RajaOngkir</div>
                <div *ngIf="failedLogin === true" class="text-danger my-3">
                  Double check your E-mail and Password!
                </div>
              </div>
              <div class="form-group mt-1">
                <label for="username">Email</label>
                <input
                  nz-input
                  nzSize="large"
                  type="email"
                  (keypress)="setRegex($event)"
                  formControlName="email"
                  class="form-control"
                  id="username"
                  aria-describedby="username"
                  placeholder="username@domain.com"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="validateEmail()"
                  [nzStatus]="validEmail ? '' : 'error'"
                />
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-danger">
                    <img
                      *ngIf="errorUser !== ''"
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="warning"
                      style="max-width: 10px; max-height: 10px"
                    />
                    {{ errorUser }}</span
                  >
                  <span
                    *ngIf="errorUser === 'Your email has not been verified'"
                    class="d-flex justify-content-end align-items-end text-blue"
                    style="cursor: pointer"
                    (click)="resendVerif($event)"
                    >Resend</span
                  >
                </div>
              </div>
              <div class="form-group mt-2">
                <label for="password">Password</label>
                <nz-input-group
                  nzSize="large"
                  [nzSuffix]="suffixTemplate"
                  [nzStatus]="isValidPass ? '' : 'error'"
                >
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="password"
                    minlength="6"
                    maxlength="32"
                    [type]="isShowPassword ? 'text' : 'password'"
                    placeholder="Enter Password"
                    (keydown.enter)="$event.preventDefault()"
                    (keyup.enter)="getCaptchaToken($event)"
                    (input)="validatePassword()"
                    (keypress)="setRegexPassword($event)"
                    (paste)="$event.preventDefault()"
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <img
                    [src]="isShowPassword ? eyeOff : eyeOn"
                    style="width: 20px !important; height: 20px"
                    (click)="isShowPassword = !isShowPassword"
                  />
                </ng-template>
              </div>
              <span class="text-danger" style="margin-top: -15px">
                <img
                  *ngIf="errMessPass !== ''"
                  src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                  alt="warning"
                  style="max-width: 10px; max-height: 10px"
                />
                {{ errMessPass }}</span
              >
              <div class="d-flex flex-row justify-content-end forgot-password">
                <a href="/forgot-password">Forgot Password?</a>
              </div>
              <div class="mb-2 mt-3">
                <button
                  [disabled]="formGroup.invalid || errorUser !== ''"
                  (click)="getCaptchaToken($event)"
                  class="btn w-100"
                  [ngClass]="
                    formGroup.invalid || errorUser !== ''
                      ? 'btn-secondary'
                      : 'btn-ro-primary'
                  "
                >
                  <div
                    class="d-flex flex-row gap-2 align-items-center justify-content-center"
                  >
                    <div style="color: white">Login</div>
                    <div
                      *ngIf="isLoading"
                      class="spinner-border spinner text-light"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
              </div>
              <div class="text-center mb-2">
                Don't have an account yet?
                <a class="text-yellow" href="/registration">Register</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.loader.getLoading()" class="cssload-container">
  <div class="cssload-speeding-wheel"></div>
</div>