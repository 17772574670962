<div class="d-flex flex-column gap-2 align-items-center justify-content-center">
  <div class="d-flex justify-content-end w-100 me-4 mt-2">
    <img (click)="handleClose($event)" style="cursor: pointer;" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg" alt="" width="30px">
  </div>
  <div class="sub-title">Lupa PIN</div>
  <div>Pilih metode untuk mengirim kode</div>
  <div class="mx-5 w-75 mt-3 px-5 d-flex flex-column gap-2">
    <button class="w-100 btn btn-primary text-white">SMS</button>
    <button class="w-100 btn btn-outline-primary mb-5">Email</button>
  </div>
</div>
