<div class="col-12 mt-2 d-flex p-2 flex-column">
  <nz-skeleton [nzLoading]="loading" [nzActive]="true">
    <div class="d-flex flex-column">
      <h6>Komerce API Access</h6>
      <span
        >The following data is a collection of API keys associated with your
        account. Data security is accounted for by the account owner.</span
      >
    </div>
  </nz-skeleton>
  <nz-skeleton *ngIf="loading" [nzLoading]="loading" [nzActive]="true"></nz-skeleton>
    <div *ngIf="!loading" class="mt-3">
      <nz-table
        #basicTable
        [nzShowPagination]="false"
        [nzData]="listOfData"
        [nzScroll]="{ x: '1280px' }"
      >
        <thead>
          <tr>
            <th style="width: 50px !important"></th>
            <th style="width: 250px !important">Api Name</th>
            <th style="width: 350px !important">Api Key</th>
            <th>Added</th>
            <th>Expires</th>
            <th>Total API HIT / Days</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="listOfData?.length === 1">
            <tr *ngFor="let data of listOfData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>
                <div class="d-flex justify-content-between text-capitalize">
                  Shipping {{ data.api_name }}
                  <img
                    *ngIf="data.api_name === 'cost'"
                    [src]="
                      expandSC
                        ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg'
                        : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg'
                    "
                    alt="down"
                    style="cursor: pointer"
                    (click)="expandSC = !expandSC"
                  />
                  <img
                    *ngIf="data.api_name === 'delivery'"
                    [src]="
                      expandSC
                        ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg'
                        : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg'
                    "
                    alt="down"
                    style="cursor: pointer"
                    (click)="expandSD = !expandSD"
                  />
                </div>
              </td>
              <td *ngIf="data.api_name === 'cost'">
                <div class="d-flex align-items-center gap-1">
                  <img
                    *ngIf="apiKeyCost === ''"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg"
                    alt="show"
                    style="cursor: pointer"
                    (click)="showKey('cost')"
                  />
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/eye-slash.svg"
                    alt="hide"
                    style="cursor: pointer"
                    *ngIf="apiKeyCost !== ''"
                    (click)="hideKey('cost')"
                  />
                  <span
                    *ngIf="apiKeyCost === ''"
                    style="text-decoration: underline"
                    (click)="showKey('cost')"
                  >
                    Show Key
                  </span>
                  <span
                    *ngIf="apiKeyCost !== ''"
                    class="text-danger"
                    style="text-decoration: underline; cursor: pointer"
                    (click)="copyValue(apiKeyCost)"
                  >
                    {{ apiKeyCost }}
                  </span>
                </div>
              </td>
              <td *ngIf="data.api_name === 'delivery'">
                <div class="d-flex align-items-center gap-1">
                  <img
                    *ngIf="apiKeyDelivery === ''"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg"
                    alt="show"
                    style="cursor: pointer"
                    (click)="showKey('delivery')"
                  />
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/eye-slash.svg"
                    alt="hide"
                    style="cursor: pointer"
                    *ngIf="apiKeyDelivery !== ''"
                    (click)="hideKey('delivery')"
                  />
                  <span
                    *ngIf="apiKeyDelivery === ''"
                    style="text-decoration: underline"
                    (click)="showKey('delivery')"
                  >
                    Show Key
                  </span>
                  <span
                    *ngIf="apiKeyDelivery !== ''"
                    class="text-danger"
                    style="text-decoration: underline; cursor: pointer"
                    (click)="copyValue(apiKeyDelivery)"
                  >
                    {{ apiKeyDelivery }}
                  </span>
                </div>
              </td>
              <td>{{ data.created_at | date : "dd/MM/yyyy" || "-" }}</td>
              <td *ngIf="data.expired_at">
                {{ data.expired_at | date : "dd/MM/yyyy" || "-" }}
              </td>
              <td *ngIf="!data.expired_at">-</td>
              <td>
                <ng-container *ngIf="isShowLimit">
                  <span class="text-bold">{{ data.total_hit }}</span
                  >/<span *ngIf="data.api_name === 'cost'">{{ data.limit }}</span>
                  <img
                    *ngIf="data.api_name === 'delivery'"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/unlimited.svg"
                    alt="unlimited"
                    width="20px"
                  />
                </ng-container>
              </td>
            </tr>
            <ng-container *ngIf="expandSC">
              <tr
                *ngFor="let items of listOfCost; let i = index"
                [ngClass]="{ 'no-border': i !== listOfCost.length - 1 }"
              >
                <td></td>
                <td colspan="4">
                  <div class="d-flex align-items-center gap-2">
                    <div
                      class="get py-1 px-4 rounded"
                      [ngClass]="classMethod(items.method)"
                    >
                      {{ items.method }}
                    </div>
                    <span class="text-capitalize">
                      {{ apiName(items.name) }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ items.request_count }}
                </td>
              </tr>
              <tr *ngIf="listOfCost.length === 0">
                <td colspan="6" class="text-center">No Data</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="expandSD">
              <tr
                *ngFor="let items of listOfDelivery; let i = index"
                [ngClass]="{ 'no-border': i !== listOfDelivery.length - 1 }"
              >
                <td></td>
                <td colspan="4">
                  <div class="d-flex align-items-center gap-2">
                    <div
                      class="get py-1 px-4 rounded"
                      [ngClass]="classMethod(items.method)"
                    >
                      {{ items.method }}
                    </div>
                    <span class="text-capitalize">
                      {{ apiName(items.name) }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ items.request_count }}
                </td>
              </tr>
              <tr *ngIf="listOfDelivery.length === 0">
                <td colspan="6" class="text-center">No Data</td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="listOfData && listOfData.length > 1">
            <tr>
              <td>1</td>
              <td>
                <div class="d-flex justify-content-between text-capitalize">
                  Shipping Cost
                  <img
                    [src]="
                      expandSC
                        ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg'
                        : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg'
                    "
                    alt="down"
                    style="cursor: pointer"
                    (click)="expandSC = !expandSC"
                  />
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center gap-1">
                  <img
                    *ngIf="apiKeyCost === ''"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg"
                    alt="show"
                    style="cursor: pointer"
                    (click)="showKey('cost')"
                  />
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/eye-slash.svg"
                    alt="hide"
                    style="cursor: pointer"
                    *ngIf="apiKeyCost !== ''"
                    (click)="hideKey('cost')"
                  />
                  <span
                    *ngIf="apiKeyCost === ''"
                    style="text-decoration: underline"
                    (click)="showKey('cost')"
                  >
                    Show Key
                  </span>
                  <span
                    *ngIf="apiKeyCost !== ''"
                    class="text-danger"
                    style="text-decoration: underline; cursor: pointer"
                    (click)="copyValue(apiKeyCost)"
                  >
                    {{ apiKeyCost }}
                  </span>
                </div>
              </td>
              <td>{{ listOfData[1].created_at | date : "dd/MM/yyyy" || "-" }}</td>
              <td>{{ listOfData[1].expired_at | date : "dd/MM/yyyy" || "-" }}</td>
              <td>
                <ng-container *ngIf="isShowLimit">
                  <span class="text-bold">{{
                    listOfData[1].total_hit | number
                  }}</span
                  >/{{ listOfData[1].limit | number }}
                </ng-container>
              </td>
            </tr>
            <ng-container *ngIf="expandSC">
              <tr
                *ngFor="let items of listOfCost; let i = index"
                [ngClass]="{ 'no-border': i !== listOfCost.length - 1 }"
              >
                <td></td>
                <td colspan="4">
                  <div class="d-flex align-items-center gap-2">
                    <div
                      class="get py-1 px-4 rounded"
                      [ngClass]="classMethod(items.method)"
                    >
                      {{ items.method }}
                    </div>
                    <span class="text-capitalize">
                      {{ apiName(items.name) }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ items.request_count }}
                </td>
              </tr>
              <tr *ngIf="listOfCost.length === 0">
                <td colspan="6" class="text-center">No Data</td>
              </tr>
            </ng-container>

            <tr>
              <td>2</td>
              <td>
                <div class="d-flex justify-content-between text-capitalize">
                  Shipping Delivery
                  <img
                    [src]="
                      expandSD
                        ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg'
                        : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg'
                    "
                    alt="down"
                    style="cursor: pointer"
                    (click)="expandSD = !expandSD"
                  />
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center gap-1">
                  <img
                    *ngIf="apiKeyDelivery === ''"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg"
                    alt="show"
                    style="cursor: pointer"
                    (click)="showKey('delivery')"
                  />
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/eye-slash.svg"
                    alt="hide"
                    style="cursor: pointer"
                    *ngIf="apiKeyDelivery !== ''"
                    (click)="hideKey('delivery')"
                  />
                  <span
                    *ngIf="apiKeyDelivery === ''"
                    style="text-decoration: underline"
                    (click)="showKey('delivery')"
                  >
                    Show Key
                  </span>
                  <span
                    *ngIf="apiKeyDelivery !== ''"
                    class="text-danger"
                    style="text-decoration: underline; cursor: pointer"
                    (click)="copyValue(apiKeyDelivery)"
                  >
                    {{ apiKeyDelivery }}
                  </span>
                </div>
              </td>
              <td>{{ listOfData[0].created_at | date : "dd/MM/yyyy" || "-" }}</td>
              <td>{{ listOfData[0].expired_at | date : "dd/MM/yyyy" || "-" }}</td>
              <td class="d-flex">
                {{ listOfData[0].total_hit | number }}/<img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/unlimited.svg"
                  alt="unlimited"
                  width="20px"
                />
              </td>
            </tr>
            <ng-container *ngIf="expandSD">
              <tr
                *ngFor="let items of listOfDelivery; let i = index"
                [ngClass]="{ 'no-border': i !== listOfDelivery.length - 1 }"
              >
                <td></td>
                <td colspan="4">
                  <div class="d-flex align-items-center gap-2">
                    <div
                      class="get py-1 px-4 rounded"
                      [ngClass]="classMethod(items.method)"
                    >
                      {{ items.method }}
                    </div>
                    <span class="text-capitalize">
                      {{ apiName(items.name) }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ items.request_count }}
                </td>
              </tr>
              <tr *ngIf="listOfDelivery.length === 0">
                <td colspan="6" class="text-center">No Data</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
</div>
