import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/api/user.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-modal-lupa-pin',
  templateUrl: './modal-lupa-pin.component.html',
  styleUrls: ['./modal-lupa-pin.component.scss']
})
export class ModalLupaPinComponent {
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
  ) {}
  handleClose(e: any): void {
    this.activeModal.dismiss();
  }
}
