import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss'],
})
export class RegisterSuccessComponent implements OnInit {
  email: string = '';
  
  constructor(private router: Router) { }
  
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
}
