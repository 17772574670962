<nz-modal
  [(nzVisible)]="isVisibleSwitch"
  nzCentered
  [nzFooter]="null"
  nzCloseIcon="null"
>
  <ng-container *nzModalContent>
    <div class="d-flex align-items-start gap-2 py-1 pr-2">
      <img
        src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
        alt="warn"
        width="50px"
      />
      <div class="d-flex flex-column">
        <h6 class="text-bold text-lg">Ready to go Live ?</h6>
        <span
          >Your account is currently in sandbox mode. To switch to Live access,
          you’ll need to upload a few supporting documents. Are you sure you
          want to proceed?</span
        >
        <div class="d-flex align-self-end gap-3 col">
          <button class="btn btn-outline-primary w-100 mt-3" (click)="handleCancel()">
            <small>Cancel</small>
          </button>
          <button class="btn btn-primary text-white w-100 mt-3 text-nowrap" (click)="handleConfirm()">
            <small>Continue to Go Live Document</small>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
