import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/api/user.service';
import { PinModel } from 'src/app/models/pin.model';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-modal-buat-pin',
  templateUrl: './modal-buat-pin.component.html',
  styleUrls: ['./modal-buat-pin.component.scss'],
})
export class ModalBuatPinComponent implements OnInit {
  pin!: PinModel
  settings: any = {
    length: 6,
    numbersOnly: true,
  };
  @Input() otpParams: any = {
    pin: '',
  };
  otp: string = '';
  isErrorMessage: boolean = false;
  destroy$ = new Subject();
  isDisabled: boolean = true;
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
  ) {}
  ngOnInit(): void {
  }
  handleClose(e: any): void {
    this.activeModal.dismiss();
  }
  selectedItem(e: any) {}

  onChange(e: string) {
    this.otp = e;
    if (e.length === 6) {
      this.isDisabled = false;
    }
  }
  handleConfirm(e:any) {
    this.otpParams.pin = this.otp
    this.userService
      .updatePin(this.otpParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.meta.code === 200) {
        this.pin = res.data
        this.toastService.show(null, 'Berhasil membuat PIN', 'success', {
          classname: 'bg-success',
          delay: 5000,
        });
        this.activeModal.dismiss();
        window.location.reload()
      } else {
      }
      })
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.isErrorMessage = false;
      this.isDisabled = true;
    }
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }

}
