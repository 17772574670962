import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { ADD_PHONE, CHECK_WA } from 'src/app/constants/endpoint.const';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-modal-add-phone',
  templateUrl: './modal-add-phone.component.html',
  styleUrl: './modal-add-phone.component.scss',
})
export class ModalAddPhoneComponent {
  @Input() isVisibleInputNumber = false;
  validNumber: boolean = true;
  errMessNumber: string = '';
  debounceTimeout: any;
  destroy$ = new Subject();

  formGroup: FormGroup = new FormGroup({
    telephone: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(12),
    ]),
  });

  constructor(
    private httpClient: HttpClient,
    private message: NzMessageService,
    private BaseService: BaseService
  ) {}

  handleOk(): void {
    const param = {
      phone_number: this.formGroup.get('telephone')?.value,
    }
    this.BaseService.putData(ADD_PHONE, param)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.message.create('success', 'Phone number added');
        this.isVisibleInputNumber = false;
      },
      (error) => {
        this.message.create('error', 'Failed to add phone number');
      });
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    clearTimeout(this.debounceTimeout);
    const input = event.target as HTMLInputElement;

    this.debounceTimeout = setTimeout(() => {
      if (input.value.length > 12) {
        input.value = input.value.slice(0, 12);
      }

      if (input.value.length === 0) {
        this.validNumber = false;
        this.errMessNumber = 'Phone number must be filled';
      } else if (!/^\d+$/.test(input.value)) {
        this.validNumber = false;
        this.errMessNumber = 'Must be a number';
      } else if (!input.value.startsWith('8')) {
        this.validNumber = false;
        this.errMessNumber = 'Phone number must start with number 8';
      } else if (input.value.length < 9) {
        this.validNumber = false;
        this.errMessNumber = 'Minimum 9 digits';
      } else {
        this.checkWA();
      }
    }, 500);
  }

  checkWA() {
    const phone = this.formGroup.get('telephone')?.value;
    const baseUrl = CHECK_WA;
    const endPoint = `?phone_no=${phone}`;
    const apiUrl = baseUrl + endPoint;

    const token = localStorage.getItem('auth-token');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);
    this.errMessNumber = 'Checking Whatsapp...';
    this.httpClient.post<any>(apiUrl, {}, { headers, context }).subscribe({
      next: (res) => {
        if (res.data === 'valid') {
          this.validNumber = true;
          this.errMessNumber = 'Number connected with Whatsapp';
        } else {
          this.validNumber = false;
          this.errMessNumber = 'Number not connected with Whatsapp';
        }
      },
      error: (error) => {
        if (error.error.data === 'invalid') {
          this.validNumber = false;
          this.errMessNumber = 'Number not connected with Whatsapp';
        } else if (error.error.code !== 1000 && error.error.code !== 1001) {
          this.message.create('error', 'Failed to check WhatsApp number');
        }
      },
    });
  }
}
