import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-alert-add-phone',
  templateUrl: './modal-alert-add-phone.component.html',
  styleUrl: './modal-alert-add-phone.component.scss'
})
export class ModalAlertAddPhoneComponent {
  @Input() isVisibleAlert = false;
  isAddNumber: boolean = false;


  handleOk(): void {
    this.isVisibleAlert = false;
    this.isAddNumber = true;
  }
}
